import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogActivityComponent } from 'app/shared/components/dialog-activity/dialog-activity.component';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { PaginatorService } from 'app/views/services/paginator.service';
import { AdminService } from 'app/views/services/admin.service';
import { Activity } from "app/views/shared/interfaces";
import { DatePipe } from '@angular/common'
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import moment from 'moment';

@Component({
  selector: 'app-admin-activity',
  templateUrl: './admin-activity.component.html',
  styleUrls: ['./admin-activity.component.css']
})
export class AdminActivityComponent implements OnInit {

  row:Activity;
  courseId:number;
  course:any;
  displayedColumns: string[] = [ 'activity','activityDate','socialLink','labelTopic','activeLabel','Borrar','Editar'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private activatedRoute: ActivatedRoute,
    private service: AdminService,
    private paginatorService: PaginatorService,
    private router: Router,
    public dialog: MatDialog,
    private loader: AppLoaderService,
    public datepipe: DatePipe

  ) { }

  ngOnInit(): void {
    this.courseId =0; 
    this.activatedRoute.paramMap.subscribe(params => {
      this.courseId = Number(params.get('id'));
      if (this.courseId) {
        this.getCourse(this.courseId);
      } else {
        this.service.openDialog('No fue posible obtener la información del módulo.');
        this.router.navigateByUrl('/dashboard/cmsCourses');
      }
    });
  }

  public getFecha(fecha) {
    moment.locale('es-MX');
    return moment(fecha).format('DD/MMM/y h:mm a');
  }

  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
  }

  getCourse(courseId:number){
    this.loader.open('Cargando información');
    const loader = this.loader.getDialog();
    this.service.getCourse(courseId).subscribe(
      async (data) => {
        loader.close();
        if (data.status == 200){
          let content = data.body.content;
          if (content?.course){
            this.course = content.course;
          } else {
            this.service.openDialog('No es posible obtener la información del curso');
          }
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
        this.service.goBack();
      },
      ()=>this.getActivity(this.courseId)
    );
  }

  getActivity(courseId:number){
    this.loader.open('Cargando Actividades');
    const loader = this.loader.getDialog();
    this.service.getActivivyXIdCatCurse(courseId).subscribe(
      (data) => {
          this.dataSource.data =  data.body.content;
          this.loader.close();
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  public onClickNuevo() {
    let item:any = {
      id : 0,
      activity : '',
      activityDate : '',
      active : 1,
      topicRequiredId : 0,
      socialLink : '',
      courseId : this.courseId
    }
     this.row = item;
    this.openDialog();
  }

  saveActivity(data){
    this.loader.open('Guardando Actividad ...');
    this.service.createActivivy(data).subscribe(
      (data:any) => {
        this.loader.close();
        this.dialogCongfirm("Actividad Guardada Correctamente");
        //this.getActivity(this.courseId);
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio1204");
      }
    );
  }

  onClickEdit(item:any){
    this.row =item;
    this.openDialog();
  }

  public openDialog() {
    this.dialog.open(DialogActivityComponent, {
      disableClose: true,
      width: '50%',
      data:this.row
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null) {
          if(this.row.id==0){
            this.saveActivity(data);
          }else{
            
            this.updateActivity(data);
          }
        } 
      });
  }

  updateActivity(data){
    this.loader.open('Editando Módulo ...');
    this.service.updateActivivy(data).subscribe(
      (data:any) => {
        this.loader.close();
        this.dialogCongfirm("Actividad actualizada correctamente");
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio1204");
      }
    );
  }

  deleteActivity(data){
    this.row = data;
    this.loader.open('Eliminando actividad ...');
    this.service.deleteActivivy(data).subscribe(
      (data:any) => {
        this.loader.close();
        this.dialogCongfirm("Actividad eliminada correctamente");
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  goToCatCourse(){
    this.router.navigate(['/dashboard/cmsCourses']);
  }

  onClickDelete(item:any){
    this.row =item;
    this.openDialogConfirm("Eliminar","¿Desea eliminar la actividad?",true);
  }

  public openDialogConfirm(title:string,content:string,viewButton_:boolean) {
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        title: title,
        content: content,
        at: '',
        viewButton:viewButton_
      }
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteActivity(this.row);
        } 
      });
  }

  openConfirm(message:string){
    return this.dialog.open(DialogConfirmComponent,{
      data:{message:message,confirm:false},
      autoFocus: false,
      disableClose: true
    }).afterClosed().toPromise();
  }

  dialogCongfirm(message:string){
        this.openConfirm(message)
        .then(
          (response) => {
            this.getActivity(this.courseId);
          }
        )
        .catch(
          (error) => {
            this.service.openDialog(`Error al: ${message}`)
          }
        );
  }
}

