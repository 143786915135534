import { AdminService } from './../../services/admin.service';
import { AfterViewInit, Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthGuard } from 'app/shared/services/auth/auth.guard';
import { Subscription } from 'rxjs';
import { LayoutService } from 'app/shared/services/layout.service';
import { NavigationService } from 'app/shared/services/navigation.service';
import { AuthenticationService } from 'app/shared/services/api/authentication.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { Profile } from 'app/views/dashboard/users/profile';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { BaseService } from 'app/views/services/base.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
  animations: [egretAnimations]
})
export class SigninComponent extends BaseService implements OnInit,AfterViewInit {
  layoutConf: any;
  menuItems:any;
  menuItemSub: Subscription;
  egretThemes: any[] = [];
  @Input() notificPanel;
  //
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;
  disableButtonLogin:boolean = false;
  form: FormGroup;
  //social attributes
  user:SocialUser = null;
  loggedIn:boolean = false;
  hide = true;

  constructor(
    private layout: LayoutService,
    public themeService: ThemeService,
    private authenticationService: AuthenticationService,
    injector:Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let redirect = 'https://snowballcommunity.com';
    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(false)
    });
    this.IsRememberAccount();
  }

  ngAfterViewInit(){
    let iSLogged = this.authenticationService.isLogged();
    if(iSLogged){
      if (this.role.isClient()) {
        this.openDialog('Inicie sesión desde la plataforma [Snowball] por favor').subscribe(
          () => {window.location.replace(this.redirect);}
        );
      } else {
        this.goToHome();
      }
      return;
    }
  }

  signin() {
    //this.submitButton.disabled = true;
    this.disableButtonLogin = true;
    this.progressBar.mode = 'indeterminate';
    const data = this.form.value;
    this.rememberAccount();
    this.service.login(data).subscribe(
      (data:any) => {
        this.progressBar.mode = 'determinate';
        if (data.roleId == this.role.CLIENT) {
          this.openDialog('Inicie sesión desde la plataforma [snowball] por favor.').subscribe(
            () => { window.location.replace(this.redirect); }
          )
        } else {
          localStorage.removeItem('infoUser');
          this.storage.clearUserInfo();
          this.storage.setUserInfo(data);
          localStorage.setItem('authToken', data.access_token);
          this.profileInfo.setinfoProfile({ username: data.username, imagenURL: data.imageUrl, userLevelId: data.userLevelId});
          this.authGuard.authToken = data.token;
          this.goToHome();
        }
      },
      (http: HttpErrorResponse) => {
        //console.log(http);
        this.progressBar.mode = 'determinate';
        switch (http.status) {
          case 0:
            this.service.openDialog('No fue posible Iniciar Sesión, servicio no disponible. Intente más tarde o contacte al área de soporte.');
            break;
          case 401:
            this.service.openDialog("No fue posible Iniciar Sesión. "+http.error.message);
            break;
          case 500:
            this.service.openDialog("No fue posible Iniciar Sesión: "+http.error.content);
            break;
        }
        this.disableButtonLogin = false;
        this.form.get('password').reset('');
        localStorage.clear();
      }
    );
  }

  rememberAccount(){
    const signinData = this.form.value;
    localStorage.removeItem('rememberMe');
    if(signinData.rememberMe){
      localStorage.setItem('rememberMe', JSON.stringify({email: signinData.email, password: signinData.password}));
    }
  }

  IsRememberAccount(){
    let account = JSON.parse(localStorage.getItem('rememberMe'));
    if(account && account.hasOwnProperty('email') && account.hasOwnProperty('password')){
      this.form.controls.rememberMe.setValue(true);
      this.form.controls.email.setValue(account.email);
      this.form.controls.password.setValue(account.password);
    }
  }

  public goToHome() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let url:string = '/dashboard/home';
    switch (userInfo.roleId){
      case this.role.ADMINSITRATOR:
      case this.role.INSTRUCTOR:
        window.location.replace(url);
        break;
    }  
  }

  ngOnDestroy() {
    if (this.menuItemSub != undefined)
      this.menuItemSub.unsubscribe();
  }
  
  changeTheme(theme) {
    this.layout.publishLayoutChange({matTheme: theme.name})
  }
  
  toggleNotific() {
    this.notificPanel.toggle();
  }
  
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  progressBarOn(): void {
    if (this.submitButton && this.progressBar){
      this.submitButton.disabled = true;
      this.progressBar.mode = 'indeterminate';
    }
  }

  progressBarOff(): void {
    if (this.submitButton && this.progressBar){
      this.submitButton.disabled = false;
      this.progressBar.mode = 'determinate';
    }
  }
}
