<div class="container-fluid">
	<div layout="row" layout-xs="column" fxLayoutAlign="space-between none" class="mb-1">
        <div flex="5"> 
          <button mat-mini-fab class="btn-secondary" focusable="false" (click)="goToCourses()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        </div>
        <div flex="85" fxLayoutAlign="space-between none" style="width: 100%;margin-top: auto;margin-left: 10px;">
            <h4 class="text-justify">{{titulo}}</h4>
        </div>
        <div flex="15"> </div>
    </div>

    <div layout="row" layout-xs="column" fxLayoutAlign="space-between none" >
        <div flex="20" style="margin-top: 10px !important;">
            <img [src]="user?.imagenURL" onError="this.src='./assets/images/perfil-usuario.png'" class="img-circle h-60 w-60 img-thumbnail">
        </div>
        <div flex="80" fxLayoutAlign="space-between none" style="width: 100%;margin-top: auto;">
            <div class="text-left w-100p p-3">
              <span class="text-bold">{{user?.username}}</span>
            </div>   
        </div>
    </div>
    <hr>
    <form [formGroup]="form" (ngSubmit)="confirmado()">
    <div layout="row" layout-xs="column">
      <div flex="">
        <mat-form-field class="example-full-width">
          <mat-label>Escribir mensaje a: {{user?.username}}</mat-label>
          <input matInput placeholder="Mensaje" formControlName="mensaje">
        </mat-form-field>
        <small *ngIf="form.controls['mensaje'].hasError('mensaje') && form.controls['mensaje'].touched">
          * Requerido.
        </small>
        <small *ngIf="form.controls['mensaje'].hasError('mensaje') && form.controls['mensaje'].touched">
          * Máximo 100 caracteres.
        </small>
      </div>
    </div>
    <div layout="row" layout-xs="column" fxLayoutAlign="space-between none" >
      <div flex="35">
          <span style="display:none ;">
            <input type="file" #fileInput ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event)" />
          </span>
          <div class="btn-group" (click)="fileInput.click()">
              <button type="button" mat-button class="btn-sm btn-secondary w-170px">
                <mat-icon>drive_folder_upload</mat-icon>  
                Adjuntar recurso:
              </button>
          </div>
      </div>
      <div flex="40" class="example-full-width">
        <mat-form-field class="example-full-width">
          <input matInput formControlName="fileName" disabled>
        </mat-form-field>  
        <small *ngIf="form.controls['fileName'].hasError('fileName') && form.controls['fileName'].touched">
          * Requerido.
        </small>
        <small *ngIf="form.controls['fileName'].hasError('fileName') && form.controls['fileName'].touched">
          * Máximo 100 caracteres.
        </small>
      </div>
      <div flex="25"></div>
  </div>
  <div flex="100" fxLayout="row" fxLayoutAlign="center center">
    <button mat-button type="submit" class="btn-sm btn-primary w-170px" [disabled]="form.invalid">
      Envíar
      <mat-icon>send</mat-icon>
    </button>
  </div>
</form>
    <hr>
	<div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
		<!---->
					<mat-card 
            *ngFor="let post of posts; let i = index;"
            class="p-3 mat-elevation-z4"
            fxLayout="row"
            fxLayout.sm="row"
            fxLayout.md="row"
            fxLayout.xs="row">
            <div class="text-right" fxFlex="10" fxLayoutAlign="end center">
              <img [src]="getUserImage(post?.chatUserImageUrl)" class="img-circle h-40 w-40 img-thumbnail">
            </div>
            <div class="p-1" fxFlex="90">
              <span class="text-bold">{{post?.name}}</span>&nbsp;<span class="text-secondary">{{dateService.parseToDiagonalFormat(post?.createdAt)}}</span>
              <p style="overflow-wrap: break-word;text-align: justify;">
                {{post?.message}}
              </p>
              <img 
                class="rounded" 
                [src]="post?.resourceUrl" 
                [style]="'max-width: 180px;height: auto;max-height:180px;'">
            </div>
          </mat-card>
          <div class="text-center m-3"> <!-- *ngIf="showLoadMore" -->
            <button mat-button class="btn-secondary mr-1 w-170px" (click)="loadMorePosts()">
              <mat-label>Cargar más</mat-label>
              <mat-icon class="ml-1">refresh</mat-icon>
            </button>
            <button mat-button class="btn-secondary w-170px" (click)="refreshChat()">
              <mat-label>Actualizar chat</mat-label>
              <mat-icon class="ml-1">refresh</mat-icon>
            </button>            
          </div>	
	</div>
</div>
<hr/>
