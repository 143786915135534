import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Observable } from 'rxjs';
import { DialogConfirmComponent } from '../dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from './admin.service';
import { DateService } from './date.service';
import { PaginatorService } from './paginator.service';
import { RoleService } from './role.service';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StorageService } from './storage.service';
import { Profile } from '../dashboard/users/profile';
import { AuthGuard } from 'app/shared/services/auth/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public router:Router;
  public service:AdminService;
  public redirect:string;
  public activatedRoute:ActivatedRoute;
  public role:RoleService;
  public dateService:DateService;
  public paginatorService:PaginatorService;
  public dialog:MatDialog;
  public loader:AppLoaderService;
  public location:Location;
  public formBuilder:FormBuilder;
  public storage:StorageService;
  public datepipe: DatePipe;
  public form:FormGroup;
  public profileInfo: Profile;
  public authGuard:AuthGuard;
  
  constructor(
    injector: Injector
  ) {
    this.redirect= '/signin';
    this.router  = injector.get(Router);
    this.service = injector.get(AdminService);
    this.activatedRoute  = injector.get(ActivatedRoute);
    this.role = injector.get(RoleService);
    this.dateService = injector.get(DateService);
    this.paginatorService = injector.get(PaginatorService);
    this.dialog = injector.get(MatDialog);
    this.loader = injector.get(AppLoaderService);
    this.location = injector.get(Location);
    this.formBuilder = injector.get(FormBuilder);
    this.storage = injector.get(StorageService);
    this.datepipe = injector.get(DatePipe);
    this.profileInfo = injector.get(Profile);
    this.authGuard = injector.get(AuthGuard);
  }

  public goToHome() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let url:string = '/dashboard/home';
    switch (userInfo.roleId){
      case 1:
        //this.router.navigate(['/dashboard/home']);
        window.location.replace(url);
        break;
      case 2:
      case 3:
        //console.log(userInfo);
        //this.router.navigate(['/dashboard/home']);
        window.location.replace(url);
        break;
    }  
  }

  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  public getVersion():string {
    return `Versión - 0.0.1`;
  }
  
  public navigateTo(url:string) {
    this.router.navigate([url],{replaceUrl:true});
  }

  public openDialog(content:string):Observable<any> {
    return this.dialog.open(DialogComponent, {
      data: {
        title: "Mensaje de la Plataforma: ",
        content: content
      },
      autoFocus: false,
      disableClose:true
    }).afterClosed();
  }

  public openConfirm(message: string):Observable<any> {
    return this.dialog.open(DialogConfirmComponent, {
      data: { message: message, confirm:true },
      autoFocus: false,
      disableClose: true
    }).afterClosed();
  }
  
  public getParams():Params {
    return this.activatedRoute.snapshot.params;
  }

  public getQueryParams():Params {
    return this.activatedRoute.snapshot.queryParams;
  }

  public proccessHttpResponse(http:HttpErrorResponse):void{
    console.log(http);
    let datetime:string = this.dateService.getCurrentDateTime();
    let message:string = (http.error.hasOwnProperty('message'))?http.error.message:"";
    switch (http.status) {
      case 0:
        this.openDialog(`Servicio no disponible intente más tarde o verifique su conexión. ${datetime}`).subscribe(
          () => {
            this.router.navigate([this.redirect],{replaceUrl:true});
          }
        );
        break;
      case 401:
        let msg:string = (this.router.url == '/sessions/signin') 
        ? http.error.message
        : `Operación no permitida: "${message}" Serás redirigido al inicio ${datetime}`
        ;
        this.openDialog(`${msg}`).subscribe(
          () => {
            if (this.router.url != '/sessions/signin') this.navigateTo(this.redirect);
          }
        )
        break;
      case 404:
        this.openDialog(`Recurso o servicio no encontrado, intente de nuevo o contacte con el área de soporte. ${datetime}`);
        break;
      case 405:
        this.openDialog(` Método de consumo no válido: [${message}]. ${datetime}`);
        break;
      case 422:
        this.openDialog(`${message} ${datetime}`);
        break;
      case 500:
        this.openDialog(`${message} ${datetime}`);
        break;
      default:
        this.openDialog(`Error con el servicio. Intente entrar nuevamente o contacte con el area de soporte. ${datetime}`).subscribe(
          () => {
            this.router.navigate([this.redirect],{replaceUrl:true});
          }
        );
        break;
    }
  }

  public signout() {
    this.service.logout().subscribe(
      (response) => {
        let body:IResponse = response.body;
        if (!body.success){
          this.openDialog(body.message);
        }
        this.storage.clearUserInfo();
        window.location.replace('http://snowballcommunity.com');
      }, 
      (error:HttpErrorResponse) => {
        this.proccessHttpResponse(error);
      }
    )
  }
  
  goTo(url:string){
    if (url.includes('home')) {
      window.location.replace(url);
    } else {
      this.router.navigateByUrl(url,{replaceUrl:true});
    } 
  }
}
export interface ICourse {
  courseId:number;
  course:string;
  productId?:string;
  salePrice:number;
};
export interface IResponse{
  success:boolean;
  message:string;
  datetime?:string;
  data:any;
};
export interface IRelCoursesPaymentsMethods{
  rcpmId:number;
  courseId:number;
  paymentMethodId:number;
  course?:string;
  paymentMethod?:string;
};
export interface ICatPaymentMethods{
  id:number;
  paymentMethod:string;
  active?:boolean;
};
export interface IStripePriceType{
  id:number;
  priceType:string;
};
export interface IStripePrice{
  priceId:string;
  productId:string;
  courseId:number;
  priceTypeId:number;
  active:boolean;
  createdAt:string;
  updatedAt:string;
  priceType:string;
  unitAmount:number;
  courseSalePrice:any;
  promotionAmount?:number;
}
