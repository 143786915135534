import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ComunicationService } from 'app/comunication.service';
import { DialogCourseComponent } from 'app/shared/components/dialog-course/dialog-course.component';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from 'app/views/services/admin.service';
import { PaginatorService } from 'app/views/services/paginator.service';
import { RoleService } from 'app/views/services/role.service';
import { CatCourse } from "app/views/shared/interfaces";


@Component({
  selector: 'app-admin-courses',
  templateUrl: './admin-courses.component.html',
  styleUrls: ['./admin-courses.component.css']
})
export class AdminCoursesComponent implements OnInit {
  row:CatCourse;
  rolVer:boolean;
  actualIndexSelected:number = -1;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor(
    private service: AdminService,
    private paginatorService: PaginatorService,
    private loader:AppLoaderService,
    private router: Router,
    public dialog: MatDialog,
    public roleService:RoleService,
    public notificationC:ComunicationService
  ) { }
  tope=0;
  intervalo;
  async mensaje() {
   await this.notificationC.setNotifications();
    this.tope++;
    //console.log("this.intervalo",this.intervalo);
  }
  intervalo_(notificationC_) {
  this.intervalo= setInterval(() => { this.mensaje(); },600000);
  }

  ngOnInit(){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let isLogged = (userInfo != null && userInfo != undefined);
    if (isLogged){
      this.notificationC.setNotifications();
      //this.intervalo_(this.notificationC);
    }
    this.rolVer = !this.roleService.isInstructor();
    if(!this.rolVer){
      this.displayedColumns = ['course', 'courseLeve','salePrice', 'applyPromotion','activeLabel','inscribed','Modules','Activiti'];
    }else{
      this.displayedColumns = ['course',/* 'name', */ 'courseLeve','salePrice','applyPromotion','Borrar','inscribed','Editar','Modules','Activiti'];
    }
    if (this.roleService.isAdmin()){
      // this.displayedColumns.push('paymentMethods');
    }
    this.getCatCourse();
  }
  getCatCourse(){
    
      this.loader.open('Cargando listado ...');
      this.service.getCoursesAll().subscribe(
        (data:any) => {
          switch(data.status){
            case 200:
              //console.log(data.body.content);
              this.dataSource.data =  data.body.content;
              this.loader.close();
              break;
              case 500:
                this.loader.close();
                console.log("500", data.body.content);
                this.service.openDialog(data.body.content);
                break;
            default:
              this.loader.close();
              this.service.openDialog("No se encontraron los servicios");
              break;
          }
          
        },
        (http: HttpErrorResponse) => {
          this.loader.close();
          this.service.openDialog('Error con el servicio: '+http.error?.content?.message);
        }
      );
  }
  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
    //console.log(this.dataSource.data);
  }
  public openDialog(title:string,content:string,viewButton_:boolean) {
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        title: title,
        content: content,
        at: '',
        viewButton:viewButton_
      }
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.desactivarCourse();
        }else{
          
          // regresar a su estado original
          let newSource = JSON.parse(JSON.stringify(this.dataSource.data));
          
          newSource[this.actualIndexSelected].active = this.row.active;
          this.dataSource.data = newSource;
          
        }
      });
  }
  public openDialogEdit() {    
    this.dialog.open(DialogCourseComponent, {
      disableClose: true,
      width: '50%',
      data:this.row
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null) {
          this.saveEditCatCourse(data);
        } 
      });
  }
  desactivarCourse(){
    let mensaje =this.row.active==1?"desactivado":"activado"
    this.row.active =this.row.active==1?0:1;
     
    this.loader.open();
    this.service.deleteCatCourse(this.row).subscribe(
      (data:any) => {
        //console.log(data);
        this.loader.close();
        let message:string = `Desactivar curso`; 
        this.openConfirm(`Curso ${mensaje} correctamente`)
        .then(
          (response) => {
            this.getCatCourse();
          }
        )
        .catch(
          (error) => {
            this.service.openDialog(`Error al: ${message}`)
          }
        );
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio1204");
      }
    );
  }
  saveEditCatCourse(data){    
    this.loader.open('Guardando Edición ...');
    const formdata = new FormData();
    formdata.append("data",JSON.stringify(data));
    if(data.file!=null){
      formdata.append("file",data.file.file)
    }else{
      formdata.append("file",null)
    }
    this.service.saveEditCatCourse(formdata).subscribe(
      (data:any) => {
        this.loader.close();
        let message:string = `Editar curso`; 
        this.openConfirm(`Curso Editado Correctamente`)
        .then(
          (response) => {
            this.getCatCourse();
          }
        )
        .catch(
          (error) => {
            this.service.openDialog(`Error al: ${message}`)
          }
        );
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio1204");
      }
    );
  }
  saveCatCourse(data){
    this.loader.open('Guardando ...');
    const formdata = new FormData();
    formdata.append("data",JSON.stringify(data));
    if(data.file!=null){
      formdata.append("file",data.file.file)
    }else{
      formdata.append("file",null)
    }
    
    this.service.saveCatCourse(formdata).subscribe(
      (data:any) => {
        this.loader.close();
        let message:string = `Crear el curso`; 
        this.openConfirm(`Curso Agregado Correctamente`)
        .then(
          (response) => {
            this.getCatCourse();
          }
        )
        .catch(
          (error) => {
            this.service.openDialog(`Error al: ${message}`)
          }
        );
       
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
        //this.service.openDialog("Error en el Servicio1204");
      }
    );
  }
  onClickDelete(item:any,$event : any, index:number){
    console.log($event);
    this.actualIndexSelected = index;
    this.row =item;
    let mensaje =this.row.active==1?"desactivar":"activar"
    this.openDialog(`Confirmación: `,"¿Seguro(a) que desea "+mensaje+" el curso?",true);
  }
  onClickEdit(item:any){
    this.row =item;
    this.openDialogEdit();
  }
  public onClickNuevo() {
    let item ={
      id:0,
      course:"",
      courseLevelId:0,
      courseLeve:"",
      applyPromotion:"0",
      inscribed:12,
      active:1,
      activeLabel:"",
      salePrice:0,
      name:"",
      userId:0,
      description:"",
      imageUrl:""
    }
    this.dialog.open(DialogCourseComponent, {
      disableClose: true,
      width: '65%',
      data:item
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null) {
          this.saveCatCourse(data);
        } 
      });
  }
  openConfirm(message:string){
    return this.dialog.open(DialogConfirmComponent,{
      data:{message:message,confirm:false},
      autoFocus: false,
      disableClose: true
    }).afterClosed().toPromise();
  }
  goToListPupil(course_id){
    this.router.navigate(['/dashboard/cmsAlumnos',course_id]);
  }
  goToModule(course_id){
    if(this.rolVer){
      this.router.navigate(['/dashboard/cmsModule', course_id]);
    }else{
      this.router.navigate(['/dashboard/course', course_id]);
    }
    
  }
  goToActivity(course_id){
    this.router.navigate(['/dashboard/cmsActivity', course_id]);
  }
  goToTeams(course_id){
    this.router.navigate(['/dashboard/cmsTeam', course_id]);
  }

  goToPaymentMethods(courseId:number): void {
    this.router.navigate(['/dashboard/cms/courses/payments/methods', courseId]);
  }
}
