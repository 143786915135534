<div class="container-fluid">
    <div layout="row" layout-xs="column" fxLayoutAlign="space-between none" >
        <div flex="5">
            <button mat-mini-fab class="btn-secondary" focusable="false" (click)="goToCatCourse()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div flex="85" fxLayoutAlign="space-between none" style="width: 100%;margin-top: auto;margin-left: 10px;">
            <h4>
                Administración de actividades - {{course?.course}}
            </h4>
        </div>
        <div flex="15">
            <button mat-button class="btn-primary loat-right" (click)="onClickNuevo()">
                <mat-label>Nueva Actividad</mat-label>
            </button>
        </div>
    </div>
	<hr/>
	<div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
		<!---->
		<div class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">

			<div class="p-2 bg-light-gray" fxLayout.md="column" fxFlex="100">
				<div class="example-container mat-elevation-z8">
						<mat-table [dataSource]="dataSource" class="example-table">
							<ng-container matColumnDef="activity">
								<mat-header-cell class="m-1" *matHeaderCellDef>Actividades</mat-header-cell>
								<mat-cell class="m-1 text-justify" *matCellDef="let row">
									<span class="mobile-label">Actividades:</span>
									{{row.activity}}
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="activityDate">
								<mat-header-cell style="margin-left: 8px;" *matHeaderCellDef>Fecha</mat-header-cell>
								<mat-cell style="margin-left: 8px;" *matCellDef="let row">
									<span class="mobile-label">Fecha:</span>
									{{ getFecha(row.activityDate)}}
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="socialLink">
								<mat-header-cell class="m-1" *matHeaderCellDef>Url social</mat-header-cell>
								<mat-cell [matTooltip]="row.socialLink" class="m-1" style="width:100px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" *matCellDef="let row">
									<span class="mobile-label">Url social:</span>
									<a href="{{row.socialLink}}" target="_blank" class="hyper">Ir al enlace</a>									
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="labelTopic">
								<mat-header-cell class="m-1" *matHeaderCellDef>Tema</mat-header-cell>
								<mat-cell class="m-1 text-justify" *matCellDef="let row">
									<span class="mobile-label">Tema:</span>
									{{row.labelTopic}}
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="activeLabel">
								<mat-header-cell class="centrado" *matHeaderCellDef>Activo</mat-header-cell>
								<mat-cell class="centrado" *matCellDef="let row">
									<span class="mobile-label">Activo:</span>
									{{row.activeLabel}}
								</mat-cell>
							</ng-container>
				
                            <ng-container matColumnDef="Borrar">
								<mat-header-cell *matHeaderCellDef>
									Eliminar
								</mat-header-cell>
								<mat-cell *matCellDef="let row" class="text-center">
									<span class="mobile-label">Eliminar:</span>
                                    <button mat-mini-fab class="btn-secondary" focusable="false" aria-label="Example icon button with a delete icon" (click)="onClickDelete(row)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
								</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Editar">
								<mat-header-cell *matHeaderCellDef>
									Editar
								</mat-header-cell>
								<mat-cell *matCellDef="let row" class="text-center">
									<span class="mobile-label">Editar:</span>
                                    <button mat-mini-fab class="btn-secondary" focusable="false" aria-label="Example icon button with a delete icon" (click)="onClickEdit(row)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
								</mat-cell>
							</ng-container>
							
							<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
							<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
						</mat-table>
					<mat-paginator [length]="5" [pageSize]="10" showFirstLastButtons></mat-paginator>
				</div>
			</div>
		</div>
	</div>
</div>
<hr/>
