import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'app/shared/services/layout.service';
import { NavigationService } from 'app/shared/services/navigation.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { AdminService } from 'app/views/services/admin.service';
import { RoleService } from 'app/views/services/role.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent implements OnInit {
  public infoUser: any;
  public labelWelcome: string;
  menuItems: any;
  menuItemSub: Subscription;
  layoutConf: any;
  egretThemes: any[] = [];
  constructor(
    private navService: NavigationService,
    private layout: LayoutService,
    public themeService: ThemeService,
    public services: AdminService,
    public role: RoleService,
    private router: Router,
  ) {
    this.navService.getMenuItems();
  }

  //@Input() loggedIn = true;

  ngOnInit() {
    this.infoUser = JSON.parse(localStorage.getItem('userInfo'));
    if (this.infoUser) {
      this.labelWelcome = '';
      switch (this.infoUser.roleId) {
        case 1:
          this.labelWelcome = 'Hola ' + this.infoUser.name;
          break;
        case 2:
          this.labelWelcome = 'Bienvenido ' + this.infoUser.name;
          break;
        case 3:
          this.labelWelcome = 'Administrador';
          break;
        default:
          break;
      }
    }
    this.initNavigation();
  }

  ngOnDestroy() {
    if (this.menuItemSub != undefined) {
      this.menuItemSub.unsubscribe();
    }
  }

  initNavigation() {
    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    this.menuItemSub = this.navService.getMenuItems()
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        let limit = 5
        let mainItems: any[] = res.slice(0, limit)
        if (res.length <= limit) {
          return this.menuItems = mainItems
        }
        let subItems: any[] = res.slice(limit, res.length - 1)
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })
        this.menuItems = mainItems
      });
  }

  showTutorial(){
    if(this.role.isClient() && this.router.url === '/dashboard/home'){
      this.services.showTutorialHomeClient();
    }else if(this.role.isClient() && this.router.url === '/dashboard/calendar'){
      this.services.showTutorialCalendarClient();
    }else if(this.role.isClient() && this.router.url === '/dashboard/courses'){
      this.services.showTutorialCoursesClient();
    }else if(this.role.isClient() && this.router.url === '/dashboard/instructors'){
      this.services.showTutorialInstructorsClient();
    }else if(this.role.isClient() && this.router.url === '/dashboard/billing'){
      this.services.showTutorialBillingClient();
    }else if(this.role.isClient() && this.router.url === '/dashboard/game'){
      this.services.showTutorialGameClient();
    }else if(this.role.isClient() && this.router.url.includes('/dashboard/course/')){
      this.services.showTutorialDetailCourseClient();
    }
  }


}
