<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">{{title}}</p>
  <button mat-icon-button aria-label="close icon" (click)="cerrarDialogo()"  >
      <mat-icon>close</mat-icon>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="confirmado()">
<div mat-dialog-content class="example-form">
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-form-field class="example-full-width">
                <mat-label>Curso</mat-label>
                <input matInput placeholder="Curso" formControlName="course">
            </mat-form-field>
            <small *ngIf="form.controls['course'].hasError('required') && form.controls['course'].touched" class="form-error-msg">
              * Requerido.
            </small>
            <small *ngIf="form.controls['course'].hasError('maxlength') && form.controls['course'].touched" class="form-error-msg">
              * Máximo 100 caracteres.
            </small>
        </div>
    </div>
    <div layout="row" layout-xs="column">
      <div flex="">
          <mat-form-field class="example-full-width">
              <mat-label>Descripción</mat-label>
              <!-- <input matInput placeholder="Descripción" formControlName="description"> -->
              <textarea matInput placeholder="Descripción" formControlName="description"></textarea>
          </mat-form-field>
          <small *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched" class="form-error-msg">
            * Requerido.
          </small>
          <small *ngIf="form.controls['description'].hasError('maxlength') && form.controls['description'].touched" class="form-error-msg">
            * Máximo 500 caracteres.
          </small>
      </div>
  </div>
    <!-- <div layout="row" layout-xs="column">
      <div flex="">
          <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Facilitador</mat-label>
              <mat-select formControlName="userId" [(ngModel)]="instructorId">
                <mat-option *ngFor="let instructor of instructores" [value]="instructor.id">
                  {{instructor.name+ ' ' +instructor.last_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <small *ngIf="form.controls['userId'].hasError('required') && form.controls['userId'].touched" class="form-error-msg">
              * Requerido.
            </small>
            
      </div>
  </div> -->

    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Nivel Curso</mat-label>
                <mat-select formControlName="nivelCursoSelected" [(ngModel)]="courseLevelId">
                  <mat-option *ngFor="let nivel of niveles" [value]="nivel.id">
                    {{nivel.course_level}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <small *ngIf="form.controls['nivelCursoSelected'].hasError('required') && form.controls['nivelCursoSelected'].touched" class="form-error-msg">
                * Requerido.
              </small>
              
        </div>
    </div>
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-checkbox class="example-margin" formControlName="IsCheckedPromo">Admitir promociones para este curso (si no admite promociones ningún alumno podrá aplicar descuentos).</mat-checkbox>
          </div>
    </div>
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-form-field class="example-full-width disabledReadonly" >
                <mat-label class="disabledReadonlyText">Inscritos</mat-label>
                <input matInput placeholder="3" formControlName="inscribed" readonly >
              </mat-form-field>
              <small *ngIf="form.controls['inscribed'].hasError('required') && form.controls['inscribed'].touched" class="form-error-msg">
                * Requerido.
              </small>
              <small *ngIf="form.controls['inscribed'].hasError('pattern') && form.controls['inscribed'].touched" class="form-error-msg">
                * Solo número.
              </small>
        </div>
    </div>
    <div layout="row" layout-xs="column">
      <div flex="">
          <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Precio</mat-label>
              <input matInput placeholder="300.00" formControlName="salePrice" type="number">
              <span matPrefix>$&nbsp;</span>
            </mat-form-field>
            <small *ngIf="form.controls['salePrice'].hasError('required') && form.controls['salePrice'].touched" class="form-error-msg">
              * Requerido.
            </small>
            <small *ngIf="form.controls['salePrice'].hasError('pattern') && form.controls['salePrice'].touched" class="form-error-msg">
              * Solo número.
            </small>
      </div>
  </div>
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-checkbox class="example-margin" formControlName="IsCheckedActivo">Activo</mat-checkbox>
        </div>
    </div>
    <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="form-group">
                  <div class="row">
                      <div class="col-sm-9" color="primary">
                          <span style="display:none ;">
                          <input type="file" #fileInput ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event)" accept=".jpg, .png" />
                          </span>
                          <div class="btn-group" (click)="fileInput.click()">
                          <button type="button" mat-button class="btn-secondary">
                              <mat-icon>drive_folder_upload</mat-icon>  
                              Agregar imagen del curso</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- <label class="col-sm-3 col-form-label" for="files">Archivos:</label> -->
          <div class="col-md-12">
              <table class="table">
                  <thead>
                      <tr>
                          <th>Imagen</th>
                          <th>Tamaño</th>
                          <th>Acción</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of arrayObjetFile">
                          <td >
                              {{ item.fileName }}
                          </td>
                          <td *ngIf="showFromDB == false">
                                  {{ item?.fileSize/1024/1024 | number:'.2' }} MB
                          </td>
                          <td *ngIf="showFromDB == true">
                            {{ data?.fileSize | number:'.2' }} MB
                          </td>
                          <td>
                              <button mat-mini-fab type="button" class="btn-secondary"  (click)="deleteFile(item)">
                                  <mat-icon>delete</mat-icon>
                              </button>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button class="btn-secondary" type="button" (click)="cerrarDialogo()">Cancelar</button>
  <button mat-button class="btn-primary" type="submit" [disabled]="form.invalid">Guardar</button>
</div>
</form>
  