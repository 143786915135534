<div class="container-fluid">
	<div layout="row" layout-xs="column" fxLayoutAlign="space-between none" class="mb-1">
        <div flex="10">
            <button mat-mini-fab class="btn-secondary" focusable="false" (click)="goToCourses()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div flex="90" fxLayoutAlign="space-between none" style="width: 100%;margin-top: auto;margin-left: 10px;">
            <h4 class="text-justify">
                Alumnos inscritos al curso "{{course}}"
            </h4>
        </div>
    </div>
	<div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
		<!---->
		<div class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">

			<div class="p-2" fxLayout.md="column" fxFlex="100">
				<mat-table #table [dataSource]="dataSource">
					<ng-container matColumnDef="imageUrl">
						<mat-header-cell *matHeaderCellDef>Foto</mat-header-cell>
						<mat-cell *matCellDef="let element"> 
							<img [src]="getUserImage(element.imageUrl)" onError="this.src='./assets/images/perfil-usuario.png'" height="50" width="50"/>
						</mat-cell>
					  </ng-container>
					  <ng-container matColumnDef="alumno">
						<mat-header-cell *matHeaderCellDef> Alumno </mat-header-cell>
						<mat-cell *matCellDef="let element"> 
						  {{element.alumno}} 
						</mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="email">
						<mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
						<mat-cell *matCellDef="let element"> 
						  {{element.email}} 
						</mat-cell>
					  </ng-container>
					  
					  <ng-container matColumnDef="conectar">
						<mat-header-cell *matHeaderCellDef>Conectar</mat-header-cell>
						<mat-cell *matCellDef="let element"> 
							<button 
								mat-mini-fab 
								class="btn-sm btn-primary" 
								focusable="false" 
								(click)="goToChat(element)"
								[matTooltip]="'Envíar mensaje a: '+element?.alumno"
								>
								<mat-icon>sms</mat-icon>
							</button>
						</mat-cell>
					  </ng-container>
					  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
				</mat-table>
				<mat-paginator [length]="5" [pageSize]="10" showFirstLastButtons></mat-paginator>
			</div>
		</div>
	</div>
</div>
<hr/>
