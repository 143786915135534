<div fxFlex="100" fxLayout="column" class="main-container">
  <!--Tabla-->
  <div fxFlex="100" fxLayout="column" class="mat-elevation-z2">
    <!--Tabla: encabezado y paginación-->
    <div class="pagination-container">
      <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">
        <div fxFlex="10" fxLayout="column" fxLayoutAlign="center center" class="pl-2">
          <div fxFlex>
            <button mat-mini-fab class="btn-secondary" (click)="navigateTo('/dashboard/cmsCourses')">
              <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
        </div>
        <div fxFlex="90" fxLayout="column" class="pl-2">
          <div class="w-100 text-purple"><h4>Métodos de pago</h4></div>
          <div class="w-100">
            <mat-label>Curso: <span [matTooltip]="course?.course" class="text-purple"><b>{{course?.course.length > 20 ? course?.course.substring(0,20)+' ...' : course?.course}}</b></span></mat-label>
          </div>
        </div>
      </div>
      <!---->
      <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center">
        <button mat-flat-button type="button" class="btn-secondary" (click)="openDialogPaymentMethod()" [disabled]="paymentMethodStripe">
          <mat-label>Asociar producto en Stripe</mat-label>
          <mat-icon>credit_card</mat-icon>
        </button>
      </div>
      <!---->
      <div class="p-1" fxFlex="50" fxLayout="row" fxLayoutAlign="end center">
        <mat-paginator [showFirstLastButtons]="false" [pageSizeOptions]="[pageSize]" [pageSize]="pageSize" (page)="onPageChange($event)" [length]="totalRows"></mat-paginator>
      </div>
    </div>
    <!--tabla y contenido-->
    <section class="mat-table-container mat-elevation-z8" tabindex="0">
      <table mat-table [dataSource]="dataSource" matSort>
  
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
          <td mat-cell *matCellDef="let row;let i = index;"> {{i+1}} </td>
        </ng-container>
        
        <ng-container matColumnDef="course">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Curso </th>
          <td mat-cell *matCellDef="let row"> {{row.course}} </td>
        </ng-container>

        <ng-container matColumnDef="paymentMethod">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Método de pago </th>
          <td mat-cell *matCellDef="let row"> <span class="text-bold text-purple">{{row.paymentMethod}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Activo </th>
          <td mat-cell *matCellDef="let row"> {{row.active ? 'Si' : 'No'}} </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Creación </th>
          <td mat-cell *matCellDef="let row"> {{row.createdAt | date}} </td>
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
          <td mat-cell *matCellDef="let row">
            <div fxFlex="100" fxLayout="row" fxLayoutGap="1%">
              <button *ngIf="row.paymentMethodId === 3" mat-mini-fab class="btn-secondary" [matTooltip]="'Administrar precios Stripe'" (click)="openDialogStripePrices(row)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No existen coincidencias con: "{{input.value}}"</td>
        </tr>
      </table>
    </section>
  </div>
</div>