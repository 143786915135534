import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit,EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from 'app/views/services/admin.service';
import { CatCourse } from "app/views/shared/interfaces";
import { FileUploader } from 'ng2-file-upload';


@Component({
  selector: 'app-dialog-course',
  templateUrl: './dialog-course.component.html',
  styleUrls: ['./dialog-course.component.css']
})
export class DialogCourseComponent implements OnInit {
  
  public form: FormGroup;
  title:string;
  niveles:[];
  instructores:[];
  arrayObjetFile:any[]=[];
  courseLevelId:number = null;
  showFromDB:boolean = true;

  constructor( public dialogo: MatDialogRef<DialogCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CatCourse,
    private loader:AppLoaderService,
    private service: AdminService,
    private dialog:MatDialog
  ) { }
    
  public uploader: FileUploader = new FileUploader({
    url: "",
    disableMultipart : false,
    autoUpload: false,
    method: 'post',
    itemAlias: 'attachment'
  });

  ngOnInit(): void {
    if(this.data.id==0){
      this.title = "Alta Curso"
    }else{
      this.title="Editar Curso";
    }
    this.form = new FormGroup({
      course: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
      ]),
      nivelCursoSelected: new FormControl('', [
        Validators.required
      ]),
      userId: new FormControl('', [
        Validators.required
      ]),
      inscribed: new FormControl('', [
        Validators.required,
        Validators.pattern("^[0-9]*$")
      ]),
      salePrice: new FormControl('', [
        Validators.required,
        Validators.pattern("^[0-9]+(.[0-9]{0,2})?$"),
      ]),
      IsCheckedPromo: new FormControl('', [
        Validators.required
      ]),
      IsCheckedActivo: new FormControl('', [
        Validators.required
      ]),
      imageUrl: new FormControl('')
    });
    this.form.controls.course.setValue(this.data.course);
    this.form.controls.description.setValue(this.data.description);
    this.form.controls.salePrice.setValue(this.data.salePrice);
    this.form.controls.inscribed.setValue(this.data.inscribed);
    this.form.controls.IsCheckedPromo.setValue((this.data.applyPromotion==0?false:true));
    this.form.controls.IsCheckedActivo.setValue((this.data.active==0?false:true));
    this.form.controls.imageUrl.setValue(this.data.imageUrl);
    this.courseLevelId = this.data.courseLevelId;
    this.instructorId  = 2;
    this.arrayObjetFile=[];
    if(this.data.imageUrl!=""){ 
      let objet = {
        id:0,
        fileName : this.data.imageUrl,
        fileSize : 0,
        file:null,
      };
      this.arrayObjetFile.push(objet);
    }
    this.getCourseLevels();
  }

  cerrarDialogo(): void {
    this.dialogo.close(null);
  }

  confirmado(): void {
    var formulario = this.form.value;
    let dataSave = {
      id:this.data.id,
      courseLevelId : formulario.nivelCursoSelected,
      applyPromotion : formulario.IsCheckedPromo?1:0,
      active : formulario.IsCheckedActivo?1:0,
      course : formulario.course,
      inscribed : formulario.inscribed,
      salePrice : formulario.salePrice,
      userId:[formulario.userId],
      description:formulario.description,
      imageUrl:formulario.imageUrl,
      file:(this.arrayObjetFile.length>0? this.arrayObjetFile[0]:null),
      platformId: this.service.PLATFORM
    }
    //
    let message:string =  (this.data.id)?`actualizar el curso`:`crear el curso`; 
    this.service.openConfirm(`¿Seguro(a) que desea ${message}?`)
    .then(
      (response) => {
        if (response) {
          this.dialogo.close(dataSave);
        }
      }
    )
    .catch(
      (error) => {
        this.service.openDialog(`Error al: ${message}`)
      }
    ); 
  }

  instructorId:number = null;
  getCourseLevels(){
    this.loader.open('Cargando listado ...');
    this.service.getCourseLevels({courseId: this.data.id}).subscribe(
      (dataResult:any) => {
        this.niveles = dataResult.body.content.niveles;
        this.instructores = dataResult.body.content.instructores;
        let instructorsIds = [];
        this.instructores.map((i:any)=>{
          if(i.selected == 1){
            instructorsIds.push(i.id)
          }
        })
        this.loader.close();
        this.form.controls.nivelCursoSelected.setValue(this.courseLevelId);console.log('courseLevewl',this.courseLevelId);
        this.form.controls.userId.setValue(this.instructorId);
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio1204");
      }
    );
  }
  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    console.log(file);
    
    if(file.name.split('.').pop() == "png" || file.name.split('.').pop() == "jpg")
    {
      const fileSizeInKB = Math.round(file.size / 1024);
      if(fileSizeInKB <= (50 * 1024) ){ //Validar archivo no mayor a 50MB

        let objet = {
          id:0,
          fileName : file.name,
          fileSize : file.size,
          file:file,
        };

        this.arrayObjetFile[0]=objet;
        this.showFromDB = false;
      }else{
        this.service.openDialog("El archivo que desea subir supera los 50 MB intente con otro por favor.");
      }



    }
    else
      this.service.openDialog("Solo se aceptan archivos tipo 'jpg' o 'png'.");    
  }
  public deleteFile(objet) {
    this.form.controls.imageUrl.setValue("");
    this.arrayObjetFile.splice(0, 1); 
  }
}