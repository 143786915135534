import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogCourseDetailsComponent } from 'app/views/dialogs/dialog-course-details/dialog-course-details.component';
import { AdminService } from 'app/views/services/admin.service';
import { RoleService } from 'app/views/services/role.service';
import { API } from 'app/views/shared/api';
import { DATA_COURSES } from 'app/views/shared/constants';
import { Course } from 'app/views/shared/interfaces';
import { isArray } from 'util';
import { egretAnimations } from '../../../shared/animations/egret-animations';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css'],
  animations: [egretAnimations]
})
export class CoursesComponent implements OnInit {

  public viewMode: string = 'grid-view';//layout config
  public currentPageP1:number;//pagination config
  public itemsPerPageP1:number = 5;//pagination config
  public rowsNumberP1:number = 0;//pagination config
  public rowNumberInitP1:number = 1;//pagination config
  //
  public currentPageP2:number;//pagination config
  public itemsPerPageP2:number = 5;//pagination config
  public rowsNumberP2:number = 0;//pagination config
  public rowNumberInitP2:number = 1;//pagination config
  public isLogged:boolean = false;
  //
  public courses:Course[];
  public _courses:any[] = [];
  public acquiredCourses:Course[] = [];
  public availableCourses:Course[] = [];
  //
  currency:string = 'USD';
  defaultCourseImage:string = 'assets/images/logo.png';

  constructor(
    private router: Router,
    private service: AdminService,
    private loader: AppLoaderService,
    private dialog: MatDialog,
    public role: RoleService,
    private _sanitizer: DomSanitizer,
    private activatedRoute:ActivatedRoute
  ) {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.isLogged = (userInfo != null && userInfo != undefined);
  }

  ngOnInit(): void {
    if (this.isLogged) {
      this.getCourses();
    } else {
      this.getCoursesNotLoggedIn();
    }
    this.activatedRoute.queryParams.subscribe(
      (params:any) => {
        try {
          if (params.hasOwnProperty('success') && params.hasOwnProperty('message')) {
            let success:boolean = params.success === '1' ? true : false;
            let message:string  = String(params.message) || undefined;
            this.service.openDialog(message).subscribe(
              () => {}
            );
          }
        } catch (error) {
          console.log(error);
          this.service.openDialog(`Ocurrió un error al obtener la información del pedido, contacte con el equipo de soporte.`);
        }
      }
    );
    
  }

  showTutorial(){
    let tutorialIsDisplayed = localStorage.getItem('tutorialCoursesIsDisplayed');
    if(this.role.isClient() && tutorialIsDisplayed!='1'){
      localStorage.setItem('tutorialCoursesIsDisplayed','1');
      setTimeout(this.service.showTutorialCoursesClient, 1000);
    }
  }

  getCourses(){
    this.loader.open('Cargando cursos');
    const loader = this.loader.getDialog();
    this.service.getCoursesByUser().subscribe(
      (data) => {
        loader.close();
        if (data.status == 200){
          const acquiredCoyrses = data.body.content.acquiredCourses;
          this.rowsNumberP1 = acquiredCoyrses.length || 0;
          if (acquiredCoyrses instanceof Array && acquiredCoyrses.length > 0){
            this.acquiredCourses = acquiredCoyrses;
          }
          const availableCourses = data.body.content.availableCourses;
          this.rowsNumberP2 = availableCourses.length || 0;
          if (availableCourses instanceof Array && availableCourses.length > 0){
            this.availableCourses = availableCourses;
          } 
          if (this.acquiredCourses.length <= 0 && this.availableCourses.length <= 0) {
            this.service.openDialog(`No hay cursos para mostrar.`);
          }
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
            this.service.openDialog(http.error.content);
            break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      },
      ()=>this.showTutorial()
    );
  }


  public getCoursesNotLoggedIn(){
    this.loader.open('Cargando cursos');
    const loader = this.loader.getDialog();
    this.service.getCoursesNoLoggedIn().subscribe(
      (data : any) => {
        loader.close();
        if (data.code == 200){

          const availableCourses = data.content;
          this.rowsNumberP2 = data.content?.length || 0;
          this.rowsNumberP1 = 0;
          if (availableCourses instanceof Array && availableCourses.length > 0){
            this.availableCourses = availableCourses;
          }
          if (this.acquiredCourses.length <= 0 && this.availableCourses.length <= 0) {
            this.service.openDialog(`No hay cursos para mostrar.`);
          }
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
            this.service.openDialog(http.error.content);
            break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      }

    );
  }

  splitCourses(){
    if (this.courses.length > 4) {
      let temp = [], courses:any[] = [];
      let i = 0;
      while (i < this.courses.length) {
        if (i % 4 == 0) {
          let k = i;
          let inc = i + 4;
          //console.log(k,inc);
          for (let j = k; j < inc; j++){
            if (this.courses[j] != undefined) {
              temp.push(this.courses[j]);
            }
          }
          courses.push(temp);
          temp = [];
          i += 4;
        } 
      }
      this._courses = courses;
      //console.log(this.courses,this.courses[0]);
    }
  }

  public scrollTop(){
    let top = document.querySelector('.shop-wrap');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  onImgError(event, id: any){
    
  }

  goToDetails(course_id){
    this.router.navigate(['/dashboard/course', course_id]);
  }

  changePagePaginator1(page:any){
    this.currentPageP1 = Number(page);
    this.rowNumberInitP1 = this.currentPageP1==1 ? 1 : ((this.currentPageP1-1) * this.itemsPerPageP1);
    this.scrollTop();
  }

  changePagePaginator2(page:any){
    this.currentPageP2 = Number(page);
    this.rowNumberInitP2 = this.currentPageP2==1 ? 1 : ((this.currentPageP2-1) * this.itemsPerPageP2);
    this.scrollTop();
  }

  getCourseDetails(courseId:number,acquiredCourse){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getCourse(courseId).subscribe(
      (data) => {
        loader.close();
        if (data.status == 200 && data.body.content[0] != undefined){
          const course = data.body.content[0];
          this.openDialogCourseDetails(course,acquiredCourse);
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
              this.service.openDialog(http.error.content);
              break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      }
    );
  }

  openDialogCourseDetails(course:any,acquiredCourse:boolean){
    const dialog = this.dialog.open(DialogCourseDetailsComponent, {
      data: {
        course:course,
        acquiredCourse:acquiredCourse
      },
      autoFocus: false
    }).afterClosed().subscribe(result => {
      //console.log(result);
    });
  }

  userHasLevelrequired(levelRequiredId:number):boolean{
    const user:any = this.service.getUserInfo();
    return (user.userLevelId >= levelRequiredId)?true:false;
  }

  buyCourse(id:number){
    this.router.navigate(['/dashboard/course/buy/',id,{isMentoria: false}]);
  }

  getImage(url:string) {
    //return this._sanitizer.bypassSecurityTrustStyle(`url(${"img-src 'self' data:;" + (url != "")?API.URL_BASE_STORAGE+url:""})`);
    return API.URL_BASE_STORAGE+url;
  }

  public onImageError($event){
    console.log($event);
    //console.log(curso);
    //console.log(index);
    
  }

  public checkImage(url:string){
    let urlImg = this._sanitizer.bypassSecurityTrustStyle(`url(${"img-src 'self' data:;" + (url != "")?API.URL_BASE_STORAGE+url:""})`);
    let existe:boolean = true;
    var img = new Image();
    img.src = `${"img-src 'self' data:;" + (url != "")?API.URL_BASE_STORAGE+url:""}`;
    img.onerror = function() {
      existe = false;
    }
    return existe; 
  }

}
