import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
  animations: [egretAnimations]
})
export class AuthComponent extends BaseService implements OnInit {

  constructor(injector:Injector) { super(injector); }

  params:any;
  ngOnInit(): void {
    this.redirect = 'https://snowballcommunity.com';
    //https://snowball.academy/auth?service_token=eyJpdiI6InJranE1RVlGa2NvY0p3VkVxbzBPVXc9PSIsInZhbHVlIjoiMUFiNHBxM2lQS3RwTnF4b0dxQzg4dTJHV1FKZzdWMFpRZm5xZ2FzQVc4QjBOSW41L1VjdnJXblVBZ2I0bmtjcGlSSGVvb2hHdXFyTjRVcUVDYkJXQzZNWFlMSjFBN1RsWHdUb2ZQNytDNm89IiwibWFjIjoiZWM5ZTBmMjgxMDkzYzg2YjdiZmY0ZTA4NDNkMTI1MjAwMDYxMDBkMjE3OGZiZTVhMDYyYWFlM2YyNWZmNWZjYyIsInRhZyI6IiJ9&service_id=2&id=44
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      this.goToHome();
    } else {
      let params = this.getQueryParams();
      if (!params) {
        this.openDialog(`Error: al obtener la información de la cuenta [parámetros no encontrados o no recibidos].`).subscribe(
          () => {window.location.replace(this.redirect);}
        );
        return;
      }
      if (
        !params.hasOwnProperty('service_token') ||
        !params.hasOwnProperty('id') ||
        !params.hasOwnProperty('service_id') 
      ) {
        this.openDialog(`Error: al obtener la información de la cuenta [parámetros incompletos].`).subscribe(
          () => {window.location.replace(this.redirect);}
        );
        return;
      }
      this.params = params;
      this.authSnowball();
    }
  }

  authSnowball(): void {
    this.loader.open();
    this.service.authSnowball(this.params).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
        }
        this.signinSnowball(body.data);
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog(http.error.message);
            break;
          default:
            this.proccessHttpResponse(http);
            break;
        }
      }
    );
  }

  signinSnowball(data:any): void {
    this.loader.open();
    this.service.signinSnowball(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message).subscribe(
            () => {window.location.replace(this.redirect)}
          );
        }
        let user:any = body.data;
        localStorage.removeItem('infoUser');
        localStorage.setItem('userInfo', JSON.stringify(user));
        localStorage.setItem('authToken', user.access_token);
        this.profileInfo.setinfoProfile({ username: user.username, imagenURL: user.imageUrl, userLevelId: user.userLevelId});
        this.authGuard.authToken = user.access_token;
        this.goToHome();
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog(http.error.message);
            break;
          default:
            this.proccessHttpResponse(http);
            break;
        }
      }
    );
  }

}
