<div class="text-center bg-base text-primary pt-1 pb-1">
  <br/>
  <span class="m-0">Notificaciones</span>
</div>
<mat-nav-list class="notification-list" role="list">
  <!-- Notification item -->
  <ng-container *ngIf="notifications.length > 0 then showNotifications else showMessage">

  </ng-container>
  <ng-template #showNotifications>
    <mat-list-item *ngFor="let n of notifications" class="notific-item" style="height: 100% !important; margin-top: 4px;margin-bottom: 4px;" role="listitem" routerLinkActive="open">
      <!--<mat-icon [color]="n.color" class="notific-icon mr-1">{{n.icon}}</mat-icon>-->
      <div class="mat-list-text">
        <h4 class="notification">{{n.message}}</h4>
        <small class="time text-muted">{{n.time}}</small>
      </div>
      <button mat-mini-fab class="btn-primary" focusable="false" (click)="deleteNotification(n.id)">
        <mat-icon>done_all</mat-icon>
      </button>
    </mat-list-item>
  </ng-template>
  <!---->
  <ng-template #showMessage>
    <div class="text-center">
      <br/>
      <mat-icon class="color-primary">notifications</mat-icon>
      <br/>
      <mat-label class="color-base">No hay notificaciones para mostrar.</mat-label>
    </div>
  </ng-template>
</mat-nav-list>
<div class="text-center mt-1 bottom" *ngIf="notifications.length">
  <button mat-button class="btn-danger" (click)="clearAll($event)">Borrar notificaciones</button>
</div>