import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}
interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  
  constructor(
  ) {
    this.getIconMenu();
  }

  //Habilita opciones de navegación
  public getIconMenu(){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //console.log(userInfo);
    this.iconMenu=[];
    if (userInfo) {
      switch (userInfo.roleId){
        case 1:
          this.iconMenu = [
            {
              name: "Inicio",
              type: "link",
              //icon: "fact_check",
              state: "dashboard/home",
            },
            {
              name: "Calendario",
              type: "link",
              //icon: "publish",
              state: "dashboard/calendar",
            },
            {
              name: "Cursos",
              type: "link",
              //icon: "publish",
              state: "dashboard/courses",
            },
            /* {
              name: "Facilitadores",
              type: "link",
              //icon: "publish",
              state: "dashboard/instructors",
            },
            {
              name: "Facturación",
              type: "link",
              //icon: "publish",
              state: "dashboard/billing",
            }, */
            
          ];
          break;
        case 2:
          this.iconMenu = [
            {
              name: "Cursos",
              type: "link",
              state: "dashboard/cmsCourses",
            },
          ];
          break;
        case 3:
          this.iconMenu = [
            {
              name: "Cursos",
              type: "link",
              state: "dashboard/cmsCourses",
            },
            {
              name: "Promociones",
              type: "link",
              state: "dashboard/cmsPromotions",
            },
            {
              name: "Reportes",
              type: "link",
              state: "dashboard/Reports",
            },
          ];
          break;
      }
    } else {
      this.iconMenu = [
        {
          name: "Inicio",
          type: "link",
          //icon: "fact_check",
          state: "home",
        },
        {
          name: "Cursos",
          type: "link",
          //icon: "publish",
          state: "courses",
        },
        /*{
          name: "¿Quiénes Somos?",
          type: "link",
          //icon: "publish",
          state: "about",
        },
        {
          name: "Contáctanos",
          type: "link",
          //icon: "publish",
          state: "contactus",
        }*/
      ];
    }
    return this.iconMenu;
  }

  public getMenuItems(){
    this.menuItems = new BehaviorSubject<IMenuItem[]>(this.getIconMenu());
    // navigation component has subscribed to this Observable
    return this.menuItems.asObservable();
  }

  iconMenu: IMenuItem[] = [];

  separatorMenu: IMenuItem[] = [];

  plainMenu: IMenuItem[] = [];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Acceso Frecuente";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.getIconMenu());
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case "separator-menu":
        this.menuItems.next(this.separatorMenu);
        break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }
}
