<div mat-dialog-title>
  <mat-icon class="text-white" color="white" (click)="close()">close</mat-icon>
</div>
<div mat-dialog-content>
  <!--<video 
    [src]="video.src" 
    controlsList="nodownload"
    type="video/mp4" 
    [controls]="true" 
    [muted]="false" 
    [autoplay]="true"
    [loop]="true"
    oncontextmenu="return false;"
    poster="assets/images/logo.png"
    >
    Tu navegador no es compatible con la etiqueta de video.
  </video>
  -->
  <div class="video-container">
    <div class="loading-overlay" *ngIf="showOverlay">
      <i class="fas fa-snowflake"></i>
    </div>
    <video
      #videoPlayer
      [src]="video.src" 
      controlsList="nodownload"
      type="video/mp4" 
      [controls]="true" 
      [muted]="false" 
      [autoplay]="true"
      [loop]="true"
      oncontextmenu="return false;"
      (loadeddata)="onVideoLoaded()" (error)="onVideoError()"
      >
      Tu navegador no soporta el tag de video.
    </video>
  </div>
</div>
