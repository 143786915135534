import { Component, OnInit, Input, Injector } from '@angular/core';
import { BaseService } from 'app/views/services/base.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent extends BaseService {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;

  constructor(injector:Injector) {super(injector);}
  isLogged:boolean = false;
  ngOnInit() {
    this.isLogged = this.service.issetUserInfo() ? true : false;
    this.menuItems = this.menuItems.filter(function(objeto) {
      return objeto.state !== 'signup';
    });
  }

  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        {name: 'SUBITEM', state: 'cards'},
        {name: 'SUBITEM', state: 'buttons'}
      ]
    });
  }
}