const API = {
  //URL_BASE: 'http://localhost:8000/api/',
  //URL_BASE_STORAGE: 'http://localhost:8000/storage',
  //URL_BASE_FRONT: 'http://localhost:4200/',//url base de angular -> al subir a servidor cambiar parámetro

  //PRODUCCIÓN
  URL_BASE: 'https://my4x4academy.com/snowball/services/public/api/',
  URL_BASE_STORAGE: 'https://my4x4academy.com/snowball/services/public',
  URL_BASE_FRONT: 'https://my4x4academy.com/snowball/',//url base de angular -> al subir a servidor cambiar parámetro
  ECHO_BROADCASTER: '',
  ECHO_CLUSTER: '',
  ECHO_KEY: '',
  ECHO_AUTH_END_POINT: '',
  ECHO_HOST: '',
  ECHO_WS_HOST: '',
  ECHO_WS_PORT: 6001,
  ECHO_PORT: 6001,
  PROD:true
}
export{ API };

const ECHO_TEST:any = {
  broadcaster: API.ECHO_BROADCASTER,
  cluster: API.ECHO_CLUSTER,
  key: API.ECHO_KEY,
  wsHost: API.ECHO_WS_HOST,
  wsPort: API.ECHO_WS_PORT,
  disableStats: true,
  enabledTransports: ['ws']
};
export {ECHO_TEST};

const ECHO_PROD:any = {
  broadcaster: API.ECHO_BROADCASTER,
  cluster: API.ECHO_CLUSTER,
  key: API.ECHO_KEY,
  wsHost: API.ECHO_WS_HOST,
  wsPort: API.ECHO_WS_PORT,
  wssPort: API.ECHO_WS_PORT,
  disableStats: true,
  encrypted: true,
  forceTLS: true,
  enabledTransports: ['ws','wss']
};
export {ECHO_PROD};