<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;" >Confirmación:</p>
  <button mat-icon-button aria-label="close icon" [mat-dialog-close]="false"  >
      <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <hr>
  <span class="f-18" [innerHTML]="message"> </span>
  <hr>
</div>
<mat-dialog-actions align="end" >
  <button class="btn-outline-light" mat-button [mat-dialog-close]="-1">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel</mat-icon>
  </button>
  <button class="btn-outline-light" mat-button [mat-dialog-close]="1">
    <mat-label class="m-1">Crear una nueva</mat-label>
    <mat-icon aria-hidden="false">add</mat-icon>
  </button>
  <button class="btn-outline-light" mat-button [mat-dialog-close]="0">
    <mat-label class="m-1">Usar la sesión existente</mat-label>
    <mat-icon aria-hidden="false">check_circle</mat-icon>
  </button>  
</mat-dialog-actions>
