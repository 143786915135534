import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  private STORAGE_KEYS:IStorageKeys = {
    USER_INFO:'userInfo',
    CHECKOUT_URL:'3278b57be41ea1a4d1d16ca271ce72e4'
  };

  public getUserInfo():any{
    let user:any = null;
    try {
      user = JSON.parse(localStorage.getItem(this.STORAGE_KEYS.USER_INFO));
    } catch (error) {
      console.error(error);
    }
    return user;
  }

  public setUserInfo(value:any):boolean{
    let success:boolean = false;
    try {
      localStorage.setItem(this.STORAGE_KEYS.USER_INFO,JSON.stringify(value));
      success = true;
    } catch (error) {
      console.error(error);
    }
    return success;
  }

  public getCheckoutUrl():string{
    let user:any = null;
    try {
      user = JSON.parse(localStorage.getItem(this.STORAGE_KEYS.CHECKOUT_URL));
    } catch (error) {
      console.error(error);
    }
    return user;
  }

  public setCheckoutUrl(value:any):boolean{
    let success:boolean = false;
    try {
      localStorage.setItem(this.STORAGE_KEYS.CHECKOUT_URL,JSON.stringify(value));
      success = true;
    } catch (error) {
      console.error(error);
    }
    return success;
  }

  public clearUserInfo():boolean {
    let success:boolean = false;
    try {
      localStorage.removeItem('infoUser');
      localStorage.removeItem('authToken');
      localStorage.removeItem(this.STORAGE_KEYS.USER_INFO);
      success = true;
    } catch (error) {
      console.error(error);
    }
    return success;
  }

}
interface IStorageKeys{
  USER_INFO:string;
  CHECKOUT_URL?:string;
}
