import { Component, OnInit } from '@angular/core';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  public initialDate:Date;
  public finalDate:Date;
  public paymentMethods:any;
  public levelsCourses:any;
  public paymentId:number = 0;
  public levelCourse:number = 0;
  public applyPromotion:number = -1;
  constructor(
    private service: AdminService,
    private loader: AppLoaderService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.initialDate = new Date();
    this.finalDate = new Date();
    this.getPaymentMethods();
  }

  public getPaymentMethods(){
    this.loader.open('Cargando filtros...');
    this.service.getPaymentMethods().subscribe(
      (data:any)=>{
        if(data.success){
          this.paymentMethods = data.data;
        }
      },
      (error)=>{},
      ()=>{
        this.getLevelsCourses()
      }
    )
  }

  public getLevelsCourses(){
    this.service.getLevelsCourses().subscribe(
      (data:any)=>{
        if(data.success){
          this.levelsCourses = data.data;
        }
      },
      (error)=>{},
      ()=>{this.loader.close();}
    )
  }

  public async downloadReporCourses() {
    this.loader.open('Descargando');
    let data: any = {
      "levelCourse": (this.levelCourse==0 ? '' : this.levelCourse),
      "applyPromotion": (this.applyPromotion==-1 ? '' : this.applyPromotion)
    };
    this.service.getReporteCoursesXLSX(data)
      .subscribe(
        (response: any) => {
          this.loader.close();
          if (response != null) {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'reporte_cursos_' + Date.now() + '.xlsx');
          } else {
            this.service.openDialog("No se han encontrado registros para procesar","ATENCIÓN");
          }
          
        },
        (err) => {        
          this.loader.close();
        }
      );
  }

  public async downloadReportPaymentHistories() {
    this.loader.open('Descargando');
    let data: any = {
      "fechaInicio": this.datepipe.transform(this.initialDate, 'y/MM/dd','UTC-6','ES-MX'),
      "fechaFin": this.datepipe.transform(this.finalDate, 'y/MM/dd','UTC-6','ES-MX'),
      "paymentMethod": (this.paymentId==0 ? '' : this.paymentId)
    };
    this.service.getReportePaymentRecordsXLSX(data)
      .subscribe(
        (response: any) => {
          this.loader.close();
          if (response != null) {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'reporte_historial_de_pagos_' + Date.now() + '.xlsx');
          } else {
            this.service.openDialog("No se han encontrado registros para procesar","ATENCIÓN");
          }
          
        },
        (err) => {
          this.loader.close();
        }
      );
  }

}
