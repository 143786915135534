import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-offer-demand-moulo2',
  templateUrl: './offer-demand-moulo2.component.html',
  styleUrls: ['./offer-demand-moulo2.component.css']
})
export class OfferDemandMoulo2Component implements OnInit {
  @ViewChild('miCanvas') canvas: ElementRef<HTMLCanvasElement>;
	public contexto: CanvasRenderingContext2D;
  public day = 1;
  public week = 1;
  public objetos = [
    { src: 'assets/modulo2/regla.png', x: 0, y: 295, w: 693, h: 129 }
  ];
  public objetoActual = null;
  public inicioX = 0;
  public minLeft = 0; // limite de movimiento izquierdo
  public minRight = 0; // limite de movimiento derecho
  public jump = 39.9; // saltos

	constructor(@Inject(MAT_DIALOG_DATA) public data: any)
  {
  }

	ngOnInit(): void
  {
    if(this.data){
      this.checkWeek(this.data.day)
    }
  }

  public checkWeek(dayOfMonth: any){
    this.day = Number(dayOfMonth);
    if(this.day >= 1 && this.day < 2){
      this.week = 1;
    }
    else if(this.day >= 2 && this.day <= 8){
      this.week = 2;
    }
    else if(this.day >= 9 && this.day <= 15){
      this.week = 3;
    }
    else if(this.day >= 16 && this.day <= 22){
      this.week = 4;
    }
    else if(this.day >= 23 && this.day <= 30){
      this.week = 5;
    }
  }

	ngAfterViewInit()
  {
    if(this.week==1) return;
		this.contexto = this.canvas.nativeElement.getContext('2d');
    let triangle = { src: 'assets/modulo2/triangulo.png', x: 301, y: 230, w: 78, h: 67.5 };
    switch (this.week) {
      case 2:
        triangle.x += 0;
        this.inicioX = -44;
        this.minLeft = -35;
        this.minRight = 534;
        break;
      case 3:
        triangle.x += 99;
        this.inicioX = -84;
        this.minLeft = -74;
        this.minRight = 450;
        break;
      case 4:
        triangle.x += 196;
        this.inicioX = -84;
        this.minLeft = -74;
        this.minRight = 326;
        break;
      case 5:
        triangle.x += 295.5;
        this.inicioX = -84;
        this.minLeft = -74;
        this.minRight = 246;
        break;
      default:
        break;
    } 
    this.objetos.push(triangle);
    this.createImage();
	}

  createImage()
  {
    this.contexto.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    for (var i = 0; i < this.objetos.length; i++) {
      const img = this.objetos[i]
      const image = new Image();
      image.src = img.src;
      image.onload = () => {
        this.contexto.drawImage(image,this.inicioX+img.x,img.y,img.w,img.h)
      };
    }
  }

	moveLeft()
  {
    if (this.inicioX >= this.minLeft) {
      this.inicioX -= this.jump;
      this.createImage();
    }
  }

  moveRight()
  {
    if (this.inicioX <= this.minRight) {
      this.inicioX += this.jump;
      this.createImage();
    }
  }

}
