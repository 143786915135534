import { AdminService } from './../../services/admin.service';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthGuard } from 'app/shared/services/auth/auth.guard';
import { Subscription } from 'rxjs';
import { LayoutService } from 'app/shared/services/layout.service';
import { NavigationService } from 'app/shared/services/navigation.service';
import { AuthenticationService } from 'app/shared/services/api/authentication.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { API } from '../../shared/api';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {


  public userEmail;
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;
  public form: FormGroup;
  public email:any = "";


  constructor(
    private router: Router,
    private loader: AppLoaderService,
    private adminServ: AdminService,
    private authGuard: AuthGuard,
    private layout: LayoutService,
    private http: HttpClient,
    public themeService: ThemeService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      newPassword: new FormControl('', [
        Validators.required,
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
      ]),
      temporalPassword: new FormControl('', [
        Validators.required,
      ]),
      email: new FormControl('', [
        Validators.required,
      ])
    },{validators: this.passwordMatchValidator});

    let emailTemp = localStorage.getItem('emailToReset');
    this.email = emailTemp ? emailTemp : '';
    this.form.get('email').setValue(this.email);
  }

  submitPass() {
    //this.submitButton.disabled = true;
    //this.progressBar.mode = 'indeterminate';
    let data = this.form.value;
    this.loader.open();
    this.http.post(API.URL_BASE + "auth/changePassword",data).toPromise()
    .then((response : any ) => {
      this.loader.close();
      if (response?.exitoso) {
        this.adminServ.openDialog(response?.content).subscribe(
          () => {
            localStorage.removeItem('emailToReset');
            this.router.navigateByUrl("/signin");
          }
        );
      } else {
        this.adminServ.openDialog(response?.content);
      }
    }).catch((error: any) => {
      this.loader.close();
      this.adminServ.openDialog("Ha ocurrido un error de comunicación");
    });
  }

  search(){
    
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['newPassword'].value === frm.controls['confirmPassword'].value ? null : {'mismatch': true};
  }

  openConfirm() {
    this.adminServ.openConfirm(`¿Seguro(a) que desea restablecer la contraseña?`)
    .then(
      (response) => {
        if (response) {
          this.submitPass();
        }
      }
    )
    .catch(
      (error) => {
        this.adminServ.openDialog(`Error al enviar código`);
      }
    ); 
  }

}
