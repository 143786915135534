import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';

@Component({
  selector: 'app-market-access-module3',
  templateUrl: './market-access-module3.component.html',
  styleUrls: ['./market-access-module3.component.css']
})
export class MarketAccessModule3Component implements OnInit {
  @ViewChild('miCanvas') canvas: ElementRef<HTMLCanvasElement>;
	public contexto: CanvasRenderingContext2D;
  // public week = 1;
  public initialItems = [
    { order: 1, src: 'assets/modulo3/precio.png', x: 0, y: 206, w: 142, h: 136 },
    { order: 2.1, src: 'assets/modulo3/lugar.png', x: 81, y: 201, w: 85, h: 53 },
    { order: 3.1, src: 'assets/modulo3/promocion.png', x: 156, y: 155, w: 79, h: 53 } // w: 79
  ];

  public objetos = [];
  public objetoActual = null;
  private initialX = -38; // -39 se posiciona desde el primer precio para calcular los saltos
  private minLeft = -29; // limite de movimiento izquierdo
  private minRight = 476; // limite de movimiento derecho
  private widthItems = 0; // longitud de items
  private pixelJumps = 30.8; // longitud saltos  en pixeles
  private minRangeQuantity = 396.06; // posicion en pixel de limita izquierdo en barra cantidad se utiliza para utilizar como eje central
  private maxRangeQuantity = 895.8; // 887.24; 890.52
  private minRangeLeftPrice = -39;
  public azuajesAvailable:any = [];
  public info:any;
  showMessageInit:string;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any, public services:AdminService, private loader: AppLoaderService) {
    this.showMessageInit = localStorage.getItem('tutorialGameIsDisplayed');
    if(this.data){
      this.info = JSON.parse(JSON.stringify(this.data));
      this.services.getAzuajesByTeam({game_sessions_id: this.data.game_sessions_id, teamId: (this.data.team || 2) }).subscribe(
        (response:any)=>{
          this.azuajesAvailable = response.data.notBought;
          this.SortAndRecalculateImages(response.data.purchased);
        },
        (error)=>{
        }
      );
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit()
  {
		this.contexto = this.canvas.nativeElement.getContext('2d');
    this.createImage();
	}

  createImage()
  {
    this.contexto.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    for (var i = 0; i < this.objetos.length; i++) {
      const img = this.objetos[i]
      const image = new Image();
      image.src = img.src;
      image.onload = () => {
        this.contexto.drawImage(image,this.initialX+img.x,img.y,img.w,img.h)
      };
    }
  }

	moveLeft()
  {
    if (this.initialX >= this.minLeft) {
      this.initialX -= this.pixelJumps;
      this.createImage();
    }
  }

  moveRight()
  {
    if (this.initialX <= this.minRight && (this.initialX+this.widthItems+81+this.pixelJumps)<=this.maxRangeQuantity) {
      this.initialX += this.pixelJumps;
      this.createImage();
    }
  }

  buyItemAzuaje(n:any){
    if(this.data.infoEquipo != null &&  n.price > this.data.infoEquipo.gasto){
      this.services.openDialog("No puedes comprar este producto. No tienes suficientes leekels en 'Gastos'");
      return ;
    }
    this.services.openConfirm(`¿Desea obtener '${n.nombre}' ${n.price!=0 ? 'con valor de '+n.price+' lekeels' : 'sin costos' }?`)
    .then(
      (response) => {
        if (response) {
          this.services.buyAzuajesByTeam({idAzuaje: n.id, game_sessions_id: this.data.game_sessions_id, teamId: (this.data.team || 2) }).subscribe(
            (response:any)=>{
              if(response.success){
                if(n.price>0){
                  //restando el monto y calculando de nuevo
                  let gastoHastaElMomento = Number(this.data.infoEquipo.gasto) - Number(n.price);
                  this.data.infoEquipo.gasto = gastoHastaElMomento;
                  this.data.infoEquipo.totalLeekels = Number(this.data.infoEquipo.inversion)+Number(this.data.infoEquipo.gasto)+Number(this.data.infoEquipo.ahorro);
                  this.updateMontosEquipo( this.data.infoEquipo);
                }
                //Actualizando montos y avisando a todos 
                this.SortAndRecalculateImages(response.data.purchased);
                this.azuajesAvailable = response.data.notBought;
              }
              this.services.openDialog(response.message);
            }
          );
        }
      }
    )
    .catch(
      (error) => {
        this.services.openDialog(`Error al: ${error.message}`)
      }
    );
  }

  SortAndRecalculateImages(ItemsToAdd:any){
    let objetosInitial = JSON.parse(JSON.stringify(this.initialItems));
    let objetosAdd = JSON.parse(JSON.stringify(ItemsToAdd));
    let objetosbk = [...objetosInitial,...objetosAdd];
    // ordenar
    objetosbk.sort((a, b) => a.order - b.order);
    // 81 es el tamaño de pixeles a la derecha que necesita estar para que cuadre la muesca del lugar con el piquito de la imagen precio
    let position = 81;
    let isThree = false;
    for (var i = 1; i < objetosbk.length; i++) {
      objetosbk[i].x = position;
      if(!isThree && objetosbk[i].order==2.1){
        isThree = true;
        position -= 9; // se resta para que se cuadren la parte sobresaliente de lugar con la parte verde de las tarjetas de promociones 
      }
      position += objetosbk[i].w;
    }
    this.widthItems = position-81;
    let calculateInitialX = ((Math.floor((this.minRangeQuantity-this.widthItems)/this.pixelJumps))*this.pixelJumps)-38;
    this.initialX = (calculateInitialX>this.minRangeLeftPrice) ? calculateInitialX : this.minRangeLeftPrice;

    let calculateMinLeft = this.initialX+9;
    this.minLeft = (calculateMinLeft>this.minLeft) ? calculateMinLeft : this.minLeft;
    
    this.objetos = objetosbk;
    this.createImage();
  }

  updateMontosEquipo(data: any){
    this.loader.open("Actualizando montos...");
    this.services.updateMontos(data).toPromise()
    .then((response : any ) => {
      this.loader.close();
      localStorage.setItem('infoEquipo',JSON.stringify(data));
      if(this.showMessageInit=='1'){
        this.services.openDialog(response.message);
      }
    }).catch((error : any ) => {
      this.loader.close();
      
    });
  }
}