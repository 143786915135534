<mat-tab-group>
    <mat-tab label="Reporte cursos">
        <mat-card fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center stretch">
            <div fxFlex="70" fxFlex.xs="100" fxLayout="row" fxLayout.xs="column">
                <mat-form-field appearance="outline">
                    <mat-label>Nivel del curso</mat-label>
                    <mat-select fxFlex="100" [(ngModel)]="levelCourse">
                        <mat-option [value]="0">Todos</mat-option>
                        <mat-option *ngFor="let level of levelsCourses" [value]="level.id">{{level.course_level}}</mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field appearance="outline" style="margin-left: 15px;">
                    <mat-label>Aplica promociones</mat-label>
                    <mat-select fxFlex="100" [(ngModel)]="applyPromotion">
                        <mat-option [value]="-1">Todos</mat-option>
                        <mat-option [value]="1">Si</mat-option>
                        <mat-option [value]="0">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="30" fxFlex.xs="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
                fxLayoutAlign.xs="center stretch">
                <button mat-button class="btn-primary" (click)="downloadReporCourses()">
                    <mat-label class="titleBtn">Reporte Cursos </mat-label>
                    <mat-icon>book</mat-icon>
                </button>
            </div>
        </mat-card>
    </mat-tab>
    <mat-tab label="Reporte historico de pagos">
        <mat-card fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center stretch">
            <div fxFlex="70" fxFlex.xs="100" fxLayout="row" fxLayout.xs="column">
                <mat-form-field appearance="outline" class="calendar">
                    <mat-label>Fecha Inicial</mat-label>
                    <input matInput matStartDate [matDatepicker]="picker1" [(ngModel)]='initialDate' [max]="finalDate">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="calendar">
                    <mat-label>Fecha Final</mat-label>
                    <input matInput matEndDate [matDatepicker]="picker2" [(ngModel)]='finalDate' [min]="initialDate">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                
                <mat-form-field appearance="outline">
                    <mat-label>Metodo de pago</mat-label>
                    <mat-select fxFlex="100" [(ngModel)]="paymentId">
                        <mat-option [value]="0">Todos</mat-option>
                        <mat-option *ngFor="let payment of paymentMethods" [value]="payment.id">{{payment.payment_method}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <div fxFlex="30" fxFlex.xs="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
                fxLayoutAlign.xs="center stretch">

                <button mat-button class="btn-primary" (click)="downloadReportPaymentHistories();">
                    <mat-label class="titleBtn">Reporte historico de pago </mat-label>
                    <mat-icon>attach_money</mat-icon>
                </button>
            </div>
        </mat-card>
    </mat-tab>
</mat-tab-group>
<!-- 
<mat-card fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center stretch">
    <div fxFlex="50" fxFlex.xs="100" fxLayout="row" fxLayout.xs="column">
        <mat-form-field appearance="standard" class="calendar">
            <mat-label>Fecha Inicial</mat-label>
            <input matInput matStartDate [matDatepicker]="picker1" [value]='inicio' [max]="fin">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="standard" class="calendar">
            <mat-label>Fecha Final</mat-label>
            <input matInput matEndDate [matDatepicker]="picker2" [value]='fin' [min]="inicio">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
    <div fxFlex="50" fxFlex.xs="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
        fxLayoutAlign.xs="center stretch">
        <button mat-button class="btn-purple" (click)="downloadReporCourses()">
            <mat-label class="titleBtn">Reporte Cursos</mat-label>
            <mat-icon>file_download</mat-icon>
        </button>

        <button mat-button class="btn-purple" (click)="downloadReportPaymentHistories();">
            <mat-label class="titleBtn">Reporte historico de pago</mat-label>
            <mat-icon>attach_money</mat-icon>
        </button>
    </div>
</mat-card> -->