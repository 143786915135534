<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">{{title}}</p>
  <button mat-icon-button aria-label="close icon" (click)="cerrarDialogo()" >
      <mat-icon>close</mat-icon>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="confirmado()">
<div mat-dialog-content class="example-form">
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Nombre de la Actividad</mat-label>
                <input matInput placeholder="Nombre de la Actividad" formControlName="activity">
            </mat-form-field>
            <small *ngIf="form.controls['activity'].hasError('required') && form.controls['activity'].touched" class="form-error-msg">
              * Requerido.
            </small>
            <small *ngIf="form.controls['activity'].hasError('maxlength') && form.controls['activity'].touched" class="form-error-msg">
              * Máximo 100 caracteres.
            </small>
        </div>
    </div>
    <div layout="row" layout-xs="column">
        <div flex="">
          <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Fecha Actividad</mat-label>
            <input matInput formControlName="activityDate" [matDatetimepicker]="dts" (click)="dts.open()" required autocomplete="false">
            <mat-datetimepicker-toggle [for]="dts" matSuffix></mat-datetimepicker-toggle>
            <mat-datetimepicker #dts type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
          </mat-form-field>
            <small *ngIf="form.controls['activityDate'].hasError('required') && form.controls['activityDate'].touched" class="form-error-msg">
              * Requerido.</small>
        </div>
    </div>
    <div layout="row" layout-xs="column">
      <div flex="">
        <mat-form-field appearance="fill" class="example-full-width">
          <mat-label>Url/Enlace para reunión</mat-label>
          <input matInput placeholder="Url social" formControlName="socialLink">
        </mat-form-field>
        <small *ngIf="form.controls['socialLink'].hasError('required') && form.controls['socialLink'].touched" class="form-error-msg">
          * Requerido.
        </small>
        <small *ngIf="form.controls['socialLink'].hasError('maxlength') && form.controls['socialLink'].touched" class="form-error-msg">
          * Máximo 100 caracteres.
        </small>
      </div>
    </div>
    <div layout="row" layout-xs="column">
      <div flex="">
        <mat-form-field appearance="fill" class="example-full-width">
          <mat-label>Tema</mat-label>
          <mat-select formControlName="topicRequiredId" (selectionChange)="getAvailableUsersByTopic($event.value)">
            <mat-option *ngFor="let tema of temas" [value]="tema.id">
              {{tema.description}}
            </mat-option>
          </mat-select>
          <mat-hint>Solo se muestran los temas que corresponden a Simulador.</mat-hint>
        </mat-form-field>
        <small *ngIf="form.controls['topicRequiredId'].hasError('required') && form.controls['topicRequiredId'].touched" class="form-error-msg">
          * Requerido.
        </small>
      </div>
    </div>
    <!---->
    <br/>
    <div layout="row" layout-xs="column">
        <div flex="" fxLayout="column">
          <mat-checkbox class="example-margin" formControlName="IsCheckedActivo">Activo</mat-checkbox>
          <small>Si la actividad está activa se podrá visualizar en calendario.</small>
        </div>
    </div>
    <br/>
    <!---->
    <div layout="row" layout-xs="column">
      <div *ngIf="data.id <= 0" flex="">
        <span>Alumnos inscritos: {{students.length}}</span>
        <br/>
        <span>Alumnos para actividad: {{group.length}}</span>
        <br/>
        <span>Alumnos restantes: {{students.length - group.length}}</span>
      </div>
      <div *ngIf="data.id > 0" flex="">
        <span>Al modificar la actividad los alumnos recibirán una notificación.</span>
      </div>
    </div>
   
</div>
<div mat-dialog-actions align="end">
  <button mat-button type="button" class="btn-secondary" (click)="cerrarDialogo()">Cancelar</button>
  <button mat-button type="submit" class="btn-primary" [disabled]="form.invalid">Guardar</button>
</div>
</form>
  