<div style="position: relative; height: 30px;">
    <button style="position: absolute; top: -0.5em; right: -0.5em;" mat-icon-button aria-label="close icon" [mat-dialog-close]>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content style="position: relative;">
    <div>
        <img src="assets/modulo2/cono-90.png" width="53" height="63" alt="" style="position: absolute; bottom: 2%; right: 8%; z-index: 1;">
        <img src="assets/modulo2/cono-3a80.png" width="61" height="64" alt="" style="position: absolute; bottom: 2%; right: 13%; z-index: 1;">
    </div>
    <div style="position: relative; min-width: 1000px;">
        <img src="assets/modulo2/tabla.jpg" width="960" height="567.5" alt="">
        <canvas #miCanvas width="960" height="567.5"
            style="position: absolute; top: 0; left: 0;">
        </canvas>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" fxLayout="column">
    <!-- <button mat-raised-button color="primary" [mat-dialog-close]>Cerrar</button> -->
    <label style="font-weight: bold; margin-bottom: 0rem;">Mover regla</label>
    <div fxLayout="row">
        <button mat-raised-button color="primary" #left (click)="moveLeft()">
            <mat-icon>arrow_back_ios</mat-icon>
            Izquierda
        </button>
        <button style="margin-left: 10px;" mat-raised-button color="primary" #right (click)="moveRight()">
            Derecha
            <mat-icon>arrow_forward_ios</mat-icon>
        </button>
    </div>
</mat-dialog-actions>