<div fxLayout="row" fxLayoutAlign="space-between center">
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;" *ngIf="!confirm">Mensaje del sistema:</p>
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;" *ngIf="confirm">Confirmación:</p>
  <button mat-icon-button aria-label="close icon" (click)="dismiss()"  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <hr>
  <span class="f-18" [innerHTML]="message"> </span>
  <hr>
</div>
<mat-dialog-actions align="end" *ngIf="confirm">
  <button class="btn-secondary" mat-button [mat-dialog-close]="false">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel</mat-icon>
  </button>
  <button class="btn-primary" mat-button [mat-dialog-close]="true">
    <mat-label class="m-1">Confirmar</mat-label>
    <mat-icon aria-hidden="false">check_circle</mat-icon>
  </button>
</mat-dialog-actions>
<mat-dialog-actions align="center" *ngIf="!confirm">
  <button class="btn-primary" mat-button [mat-dialog-close]="true">
    <mat-label class="m-1">Aceptar</mat-label>
    <mat-icon aria-hidden="false">check_circle</mat-icon>
  </button>
</mat-dialog-actions>