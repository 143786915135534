import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Profile } from 'app/views/dashboard/users/profile';
import { AdminService } from 'app/views/services/admin.service';

@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true; // Set this value dynamically
  userInfo:any;

  constructor(
    private router: Router,
    private profile: Profile,
    private service: AdminService
  ) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //console.log("AuthGuard",state);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo != null && userInfo != undefined){
      this.authToken = userInfo.access_token;
      if(!this.getAccessPermissionsMenu(userInfo.roleId,state.url)){
        this.router.navigate(['/signin']);
        return false;
      }
      if (this.authToken != null && this.authToken != "") {
        return true;
      }
    } 
    this.router.navigate(['/signin']);
    return false;
  }

  public getAccessPermissionsMenu(roleId,url){
    let iconMenu =[];
    //console.log(userInfo);
    switch (roleId){
      case 1:
        this.getUserInformation();
        iconMenu = [
          "/dashboard/home",
          "/dashboard/calendar",
          "/dashboard/courses",
          "/dashboard/instructor",
          "/dashboard/billing",
          "/dashboard/wall",
          "/dashboard/profile",
          "/dashboard/course/",
          "/dashboard/studyPlan/",
          "/dashboard/game",
          "/dashboard/simulador-modulo-2",
          "/dashboard/simulador-modulo-3",
          "/dashboard/simulador-modulo-4",
          "/dashboard/privacy",
          "/dashboard/terms"
        ];
        break;
      case 2:
        this.getUserInformation();
        iconMenu = [
          "/dashboard/home",
          "/dashboard/cmsCourses",
          "/dashboard/cmsTeam/",
          "/dashboard/profile",
          "/dashboard/course",
          "/dashboard/cmsAlumnos/",
          "/dashboard/cmsActivity/",
          "/dashboard/wall",
          "/dashboard/game-instructor",
          "/dashboard/simulador-instructor-modulo-2",
          "/dashboard/simulador-instructor-modulo-3",
          "/dashboard/simulador-instructor-modulo-4",
          "/dashboard/privacy",
          "/dashboard/terms"
        ];
        break;
      case 3:
        iconMenu = [
          "/dashboard/home",
          "/dashboard/cmsTeam/",
          "/dashboard/cmsPromotions",
          "/dashboard/Reports",
          "/dashboard/cmsCourses",
          "/dashboard/cmsModule/",
          "/dashboard/cmsActivity/",
          "/dashboard/cmsTopic/",
          "/dashboard/game-instructor",
          "/dashboard/simulador-instructor-modulo-2",
          "/dashboard/simulador-instructor-modulo-3",
          "/dashboard/simulador-instructor-modulo-4",
          "/dashboard/privacy",
          "/dashboard/terms",
          "/dashboard/cmsAlumnos/",
          "/dashboard/cms/courses/payments/methods",
          "/dashboard/cms/courses/stripe/prices"
        ];
        break;
    }
    // const filtered = iconMenu.filter(function (str) { return url.includes(str); });
    const filtered = iconMenu.filter((str)=> url.includes(str));
    return filtered.length>0?true:false;
  }

  async getUserInformation(){
    this.userInfo = await this.service.getUserInfo();
    this.profile.setinfoProfile(this.userInfo);
  }

}
