<app-header-top-sessions></app-header-top-sessions>
<!---->
<div 
  class="container-sessions"
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  [ngStyle.xs]="'background:none;'"
  >
  <div 
    style="background-color:none !important;"
    fxFlex="100"
    fxLayout="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    >
    <div 
      style="background-color:none !important;"
      fxFlex="20"
      fxFlex.sm="100"
      fxLayoutAlign.sm="end center"
      fxFlex.xs="30"
      fxLayoutAlign.xs="center center"
      fxLayoutAlign="center center"
      fxLayout="column"
      [ngClass.xs]="'bg-image-sessions'"
      >
      <h3 style="text-align: left; margin-left:40%; width: 100%;"  [ngStyle.sm]="{'margin-top': '15%', 'width': '50%', 'margin-left': '0%' }"  [ngStyle.xs]="{'margin-top': '15%', 'width': '50%', 'margin-left': '0%' }" ><span [ngClass.xs]="'ts-light'">Bienvenido(a)</span></h3>
      <br>
      <h3 [ngStyle.xs]="{'margin-left': '0%', 'width': '50%' }" [ngStyle.sm]="{'margin-top': '15%', 'width': '50%', 'margin-left': '0%' }"  style="font-size:1em; font-weight: light; text-align: left; margin-left:40%;width: 100%;" ><span [ngClass.xs]="'ts-light text-justify'"></span>Regístrate para utilizar nuestra plataforma de aprendizaje.</h3>
      <br>
    </div>
    <!---->
    <div 
      fxFlex="80"
      class="page-wrap"
      style="height: 100vh;"
      >
      <div class="session-form-hold" [@animate]="{value:'*',params:{delay: '200ms', x:'250px'}}">
        <div class="registration-one__right wow slideInRight" data-wow-delay="100ms"
          data-wow-duration="2500ms">
          <div class="registration-one__right-form">
            <div class="title-box">
              <h4>Inicia sesión</h4>
            </div>
            <div class="form-box">
              <div class="text-center mb-1">
                <mat-label>Entra con tu cuenta:</mat-label>
              </div>
              <form [formGroup]="form" (ngSubmit)="signin()">
                <div class="form-group">
                  <input type="email" matInput [formControl]="form.controls['email']" placeholder="ejemplo@dominio" value="">
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                    class="form-error-msg"> 
                      * Requerido
                  </small>
                  <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
                    class="form-error-msg"> 
                      * Correo electrónico no válido.
                  </small>
                </div>
                <div class="form-group form-group-center">
                  <input [type]="hide ? 'password' : 'text'" matInput type="password" [formControl]="form.controls['password']" placeholder="*****" value="">
                  <button type="button" mat-button matSuffix (click)="hide = !hide;" [matTooltip]="hide ? 'Mostrar' : 'Ocultar'">
                    <mat-icon style="width: auto !important;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" 
                    class="form-error-msg"> 
                      * Requerido
                  </small>
                </div>
                <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
                <button type="submit" mat-raised-button class="btn btn-primary full-width mb-1 mt-2" [disabled]="form.invalid || disableButtonLogin">
                  Iniciar Sesión
                </button>
                <div fxLayoutAlign="center">
                  <mat-checkbox color="primary" formControlName="rememberMe">
                    <mat-label>Recuerda mis credenciales</mat-label>
                  </mat-checkbox>
                </div>
                <div fxLayoutAlign="center">
                  <a class="hyper" [routerLink]="'/forgot'">¿Olvidaste tu Contraseña?</a>
                </div>
                <div fxLayoutAlign="center">
                  ¿No tienes cuenta?&nbsp;<a class="hyper" [routerLink]="'/signup'">Regístrate</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="100" fxFlexFill>
    <app-footer></app-footer>  
  </div>
</div>