import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, UrlSerializer } from '@angular/router';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { RoleService } from 'app/views/services/role.service';

@Component({
  selector: 'app-init-game-dialog',
  templateUrl: './init-game-dialog.component.html',
  styleUrls: ['./init-game-dialog.component.css']
})
export class InitGameDialogComponent implements OnInit {
  public codigo:string;
  public courses:Array<any> = [];
  public modules:Array<any> = [];
  public course:any;
  public module:any;
  public simulatorModuleNumber:any;
  public disabledButtonConnect:boolean = false;
  public message:string = '';


  constructor(
    public dialogo: MatDialogRef<InitGameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loader: AppLoaderService,
    public services: AdminService,
    public role: RoleService,
    public dialog: MatDialog,
    public location:Location,
    private router: Router) { }

  ngOnInit(): void {
    this.loader.open('Obteniendo cursos..');
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let data:any = { rol: userInfo.roleId, idUser: userInfo.id };
    this.services.getCoursesPurchasedOrTaughtForGame(data).subscribe(
      (result:any)=>{
        this.loader.close();
        if(result.success){
          this.services.openDialog('Esta es una actividad online en vivo..');
          this.courses = result.data;
        } else {
          this.dialogo.close();
          this.services.openDialog(result.message);
        }
      },
      (error:HttpErrorResponse)=>{
        this.loader.close();
        console.log(error);
        if(error?.error?.message){
          this.services.openDialog(error.error.message);
          return;
        }
        this.services.openDialog('No fue posible obtener los cursos o no hay actividades agendadas.');
      }
    )
  }

  getModules(){
    if(this.role.isClient()){
      return;
    }
    this.loader.open('Obteniendo modulos..');
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.services.getModulesByCourseForGame({ idCourse: this.course.id, idUser: userInfo.id }).subscribe(
      (result:any)=>{
        this.loader.close();
        if(result.success==true){
          this.modules = result.data;
        }else{
          this.modules = [];
          this.services.openDialog(result.message);
        }
      },
      (error)=>{
        this.loader.close();
        if(error?.error?.message){
          this.services.openDialog(error.error.message);
          return;
        }
        this.services.openDialog('No se pudo obtener los cursos.');
      }
    )
  }

  cerrarDialogo(): void {
    this.dialogo.close(false);
  }

  validarCodigo(){
    let user = JSON.parse(localStorage.getItem('userInfo'));
    if(
      (this.role.isClient() && (!this.codigo || !this.course || !this.course.id))
      || (this.role.isInstructor() && (!this.codigo || !this.course || !this.course.id || !this.module || !this.module.module_id))
      ){
        this.services.openDialog('Faltan campos por seleccionar.');
        return;
    }
    let params = {
      isClient: this.role.isClient(),
      codigo: this.codigo,
      courseId: this.course.id,
      userId: user.id,
      moduleId: (this.role.isInstructor() ? this.module.module_id : 0)
    };
    
    this.loader.open('Creando session..');
    this.services.validateCodeGame(params).subscribe(
      (response:any)=>{
        this.loader.close();
        if(response.success){
          // si existe la session y es cliente solo entra
          if(this.role.isClient()){
            this.services.resetSessionGame();
            localStorage.setItem('codigoAcceso',this.codigo);
            localStorage.setItem('linkMeetingZoom',String(this.course.social_link || ''));
            localStorage.setItem('courseIdAcceso',this.course.id.toString());
            localStorage.setItem('moduleId',response.data[0]?.module_id.toString());
            this.simulatorModuleNumber = response.module;
            this.dialog.closeAll();
            this.goToGameByUserType(user.roleId);
          }else if(this.role.isInstructor() || this.role.isAdmin()){
            this.services.resetSessionGame();
            if(response.data.length>0 && (this.course.id != response.data[0]?.course_id || this.module.module_id != response.data[0].module_id)){
              this.services.openDialog('Este código ya se utilizo para otro curso o modulo y no se puede reutilizar para este curso.');
              return;
            }
            // existe una sesion y es instructor crea o resetea
            this.services.openNewSesionDialog("Se ha encontrado una sesión existente ¿Desea usar la existente o crear una nueva?")
            .then((response: any) => {
              this.services.resetSessionGame();
              // Sesion nueva resetea la existente
              if (response  == 1) {
                this.resetSession();
              }
              //Usar la existente sin reset
              else if(response  == 0) {
                this.dialog.closeAll();
                localStorage.setItem('codigoAcceso',this.codigo);
                localStorage.setItem('linkMeetingZoom',String(this.course.social_link || ''));
                localStorage.setItem('courseIdAcceso',this.course.id.toString());
                localStorage.setItem('moduleId',this.module.module_id.toString());
                this.goToGameByUserType(user.roleId);
              }else{
                this.loader.close();
              }
            }).catch((error:any) => {
            });
          }
        }else{
          this.services.resetSessionGame();
          if(this.role.isClient()){
            this.services.openDialog(response.message);
          }else if(this.role.isInstructor() || this.role.isAdmin()){
            // no hay session crear una nueva
            this.dialog.closeAll();
            this.createSession();
          }
        }
      },
      (error)=>{
        this.loader.close();
        if(error?.error?.message){
          this.services.openDialog(error.error.message);
          return;
        }
        this.services.openDialog('No se pudo crear la sesión.');
      }
    );
  }

  resetSession(){
    let user = JSON.parse(localStorage.getItem('userInfo'));
    let params = {
      codigo: this.codigo,
      courseId: this.course.id,
      moduleId: this.module.module_id
    };
    this.loader.open('Creando session..');
    this.services.resetSessionGame();
    this.services.resetGameSession(params).subscribe(
      (response:any)=>{
        this.dialog.closeAll();
        this.loader.close();
        if(response.success){
          localStorage.setItem('codigoAcceso',this.codigo);
          localStorage.setItem('linkMeetingZoom',String(this.course.social_link || ''));
          localStorage.setItem('courseIdAcceso',this.course.id.toString());
          localStorage.setItem('moduleId',this.module.module_id.toString());
          this.dialogo.close(false);
          this.goToGameByUserType(user.roleId);
          return;
        }
        this.services.openDialog('No se pudo crear la sesión.');
      },
      (error)=>{
        this.loader.close();
        if(error?.error?.message){
          this.services.openDialog(error.error.message);
          return;
        }
        this.services.openDialog('No se pudo crear la sesión.');
      }
    );
  }

  createSession(){
    let user = JSON.parse(localStorage.getItem('userInfo'));
    let params = {
      codigo: this.codigo,
      courseId: this.course.id,
      id: 1,
      userId:user.id,
      moduleId: this.module.module_id,
      isClient: this.role.isClient()
    };
    this.loader.open('Creando session..');
    this.services.createCodeGame(params).subscribe(
      (response:any)=>{
        this.loader.close();
        if(response.success){
          localStorage.setItem('codigoAcceso',this.codigo);
          localStorage.setItem('linkMeetingZoom',String(this.course.social_link || ''));
          localStorage.setItem('courseIdAcceso',this.course.id.toString());
          localStorage.setItem('moduleId',this.module.module_id.toString());
          this.dialogo.close(false);
          this.goToGameByUserType(user.roleId);
          return;
        }
        this.services.openDialog('No se pudo crear la sesión.');
      },
      (error)=>{
        this.loader.close();
        if(error?.error?.message){
          this.services.openDialog(error.error.message);
          return;
        }
        this.services.openDialog('No se pudo crear la sesión.');
      }
    );
  }

  goToGameByUserType(roleId:number){
    let path = this.location.path();
    let urlToGo = '';
    if(roleId == 2 || roleId == 3){
      this.simulatorModuleNumber = Number(this.module.moduleOrder);
    }
    
    switch (this.simulatorModuleNumber) {
      case 1:
        urlToGo = (roleId == 1) ? '/dashboard/game' : '/dashboard/game-instructor';
        break;
      case 2:
        urlToGo = (roleId == 1) ? '/dashboard/simulador-modulo-2' : '/dashboard/simulador-instructor-modulo-2';
        break;
      case 3:
        urlToGo = (roleId == 1) ? '/dashboard/simulador-modulo-3' : '/dashboard/simulador-instructor-modulo-3';
        break;
      case 4:
        urlToGo = (roleId == 1) ? '/dashboard/simulador-modulo-4' : '/dashboard/simulador-instructor-modulo-4';
        break;
      default:
        break;
    }
    if(path==urlToGo){
      window.location.reload();
    }else{
      this.router.navigate([urlToGo]);
    }
  }

  validateText(event:any){
    let regxp = /^[a-z0-9]+$/gi;
    if(event.target.value==''){
      this.disabledButtonConnect = true;
      this.message = '* Ingrese un código.';
      return;
    }
    if(event.target.value.length > 250){
      this.disabledButtonConnect = true;
      this.message = '* La longitud debe ser menor a 250.';
      return;
    }
    if(regxp.test(event.target.value)){
      this.disabledButtonConnect = false;
      this.message = '';
    }else{
      this.disabledButtonConnect = true;
      this.message = '* Solo se permiten caracteres alfanuméricos mayúsculas/minúsculas sin espacios (a-z).';
    }
  }

}
