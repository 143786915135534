import { CalendarEventAction, CalendarEvent } from 'angular-calendar';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';

export class EgretCalendarEvent implements CalendarEvent {
  _id?: string;
  start: Date;
  end?: Date;
  title: string;
  color?: {
    primary: string;
    secondary: string;
  };
  actions?: CalendarEventAction[];
  allDay?: boolean;
  cssClass?: string;
  resizable?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };
  draggable?: boolean;
  meta?: {
    location: string,
    notes: string
  };
  //
  topicRequiredId:number;
  socialLink:string;

  constructor(data?) {
    data = data || {};
    this.start = new Date(data.start) || startOfDay(new Date());
    this.end = data.end ? new Date(data.end) : null;
    this._id = data._id || '';
    this.title = data.title || '';
    this.color = {
      primary: data.color && data.color.primary || '#FFC107',
      secondary: data.color && data.color.secondary || '#FF7C17'
    };
    this.draggable = data.draggable || false;
    this.resizable = {
      beforeStart: data.resizable && data.resizable.beforeStart || false,
      afterEnd: data.resizable && data.resizable.afterEnd || false
    };
    this.actions = data.actions || [];
    this.allDay = data.allDay || false;
    this.cssClass = data.cssClass || '';
    this.meta = {
      location: data.meta && data.meta.location || '',
      notes: data.meta && data.meta.notes || ''
    };
    //
    this.socialLink = data.socialLink || '';
    this.topicRequiredId = data.topicRequiredId || 0;
  }
}
