<div style="position: relative; height: 30px;">
    <button style="position: absolute; top: -0.5em; right: -0.5em;" mat-icon-button aria-label="close icon" [mat-dialog-close]="data?.infoEquipo">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div style="position: relative; min-width: 1000px;">
        <img src="assets/modulo3/tabla.jpg" alt="Workplace" usemap="#workmap" width="984" height="476" alt="">
        <canvas ngModel #miCanvas width="984" height="476"
            style="position: absolute; top: 0; left: 0;">
        </canvas>
    </div>
    <div align="center">
        <ng-container *ngIf="info && info.isLeader">
            <div style="font-size: 16px; margin: 5px; font-weight: bold;">Comprar items</div>
            <ng-container *ngFor="let azuaje of azuajesAvailable">
                <button [ngStyle]="{'border-bottom': azuaje?.order == 2? '#b13f43 10px' : '#88b050 10px'}" style="margin: 5px 5px 5px 5px; border-bottom-style: solid;" mat-raised-button color="primary" (click)="buyItemAzuaje(azuaje)">{{azuaje?.nombre}} {{azuaje?.price > 0 ? 'LK '+azuaje?.price : '' }}</button>
            </ng-container>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" fxLayout="column">
    <!-- <button mat-raised-button color="primary" [mat-dialog-close]="data?.infoEquipo">Cerrar</button> -->
    <label style="font-weight: bold; margin-bottom: 0rem;">Mover regla</label>
    <div fxLayout="row">
        <button mat-raised-button color="primary" #left (click)="moveLeft()">
            <mat-icon>arrow_back_ios</mat-icon>
            Izquierda
        </button>
        <button style="margin-left: 10px;" mat-raised-button color="primary" #right (click)="moveRight()">
            Derecha
            <mat-icon>arrow_forward_ios</mat-icon>
        </button>
    </div>
</mat-dialog-actions>