<app-header-top-sessions fxFlex *ngIf="!activo"></app-header-top-sessions>
<app-header-top fxFlex *ngIf="activo"></app-header-top>
<div [style.margin-top]="getMargin()" style="overflow: hidden;width: 100%;">
	<div class="page-wrapper">

			<div class="stricky-header stricked-menu main-menu">
					<div class="sticky-header__content">

					</div><!-- /.sticky-header__content -->
			</div><!-- /.stricky-header -->


			<section class="main-slider main-slider-one">
					<div class="swiper-container thm-swiper__slider" data-swiper-options='{"slidesPerView": 1, "loop": false, "effect": "fade", "pagination": {
			"el": "#main-slider-pagination",
			"type": "bullets",
			"clickable": true
			},
			"navigation": {
			"nextEl": "#main-slider__swiper-button-next",
			"prevEl": "#main-slider__swiper-button-prev"
			},
			"autoplay": {
			"delay": 7000
			}}'>

							<div class="swiper-wrapper">
									<!--Start Single Swiper Slide-->
									<div class="swiper-slide">
											<div class="shape1"></div>
											<div class="shape2"></div>
											<div class="image-layer"
												style="background-image: url(assets/images/banners/SBAcademmy-Banner1.jpg);background-size: contain;background-position: center;background-repeat: no-repeat;">
											</div>
											<!-- /.image-layer -->
											<div class="container container-banner">
													<div class="main-slider__content">
															<!--<div class="main-slider__content-icon-one">
																<span class="icon-lamp"></span>
															</div>
															<div class="main-slider__content-icon-two">
																<span class="icon-human-resources"></span>
															</div>-->
															<div class="main-slider__content-tagline">
																<!--<h2>El espacio donde la nueva escuela, la vieja escuela y la no escuela convergen</h2>-->
															</div>
															<h2 class="main-slider__content-title"><!--Libera tu<br>potencial--></h2>
															<p class="main-slider__content-text"><!--Intégrate a la plataforma No. 1 de habla hispana<br>&nbsp;especializada en cursos y talleres para start ups.--></p>
															<div class="main-slider__content-btn">
																<!--<a [routerLink]="['/signin']" class="thm-btn">Únete a la Comunidad 4x4 Emprendedores Ahora</a>-->
															</div>
															<div class="main-slider-one__img">
																<!--<img src="assets/new/images/backgrounds/main-slider-v1-img.png" alt="" />-->
															</div>
													</div>
											</div>
									</div>
									<!--End Single Swiper Slide-->
							</div>
					</div>
			</section>

			<!--About One Start-->
			<section class="about-one clearfix">
					<div class="container">
							<div class="row">
									<!--Start Why Choose One Right-->
									<div class="col-xl-6 col-lg-12">
											<div class="video-two__box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms"
											style="background-image: url(assets/images/posters/Miniatura-snooowgle.png); background-position: center center;background-size: cover;background-repeat: no-repeat;">
											<div class="video-two__box-title">
													<div class="about-one__left-overlay">
															<div class="icon">
																	<span class="icon-relationship"></span>
															</div>
															<div class="title">
																	<h6>+ <span class="odometer" data-count="6000">6000</span><br>Estudiantes<br>Activos</h6>
															</div>
													</div>
											</div>
											<div class="icon wow zoomIn" data-wow-delay="300ms" data-wow-duration="1500ms">
													<a class="video-popup" title="Video"
															(click)="openDialogVideo('https://my4x4academy.com/snowball/Video-Snooowgle.mp4')">
															<span class="icon-play ml-6"></span>
													</a>
													<span class="border-animation border-1"></span>
													<span class="border-animation border-2"></span>
													<span class="border-animation border-3"></span>
											</div>
											</div>
									</div>
									<!--End Why Choose One Right-->                    

									<!-- Start About One Right-->
									<div class="col-xl-6">
											<div class="about-one__right">
													<div class="section-title">
															<span class="section-title__tagline title-about">Más de 500,000</span>
															<h2 class="section-title__title text-about">emprendedores e inversionistas forman parte de nuestra comunidad.</h2>
													</div>
													<div class="about-one__right-inner">
															<p class="about-one__right-text">Te invitamos a conocer más contenido para potenciar tu aprendizaje</p>

															<div class="about-one__btn">
																	<a href="http://www.youtube.com/@SnowballCommunity-ej6og" target="_blank" class="thm-btn">Visítanos en YouTube</a>
															</div>
													</div>
											</div>
									</div>
									<!-- End About One Right-->
							</div>
					</div>
			</section>
			<!--About One End-->  

			<!--Video One Start-->
			<section class="video-three">
					<div class="container">
							<div class="row">
									<div class="col-xl-12">
											<div class="video-three__box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms"
													style="background-image: url(assets/images/posters/Miniatura-Snowball-Community.png); background-repeat: no-repeat; background-size:cover; background-position: center;" >
													<div class="icon wow zoomIn" data-wow-delay="300ms" data-wow-duration="1500ms">
															<a class="video-popup" title="Video"
																	(click)="openDialogVideo('https://my4x4academy.com/snowball/Video-SBC-Lanzamiento.mp4')">
																	<span class="icon-play ml-6"></span>
															</a>
															<span class="border-animation border-1"></span>
															<span class="border-animation border-2"></span>
															<span class="border-animation border-3"></span>
													</div>
											</div>
									</div>
							</div>
					</div>
			</section>
			<!--Video One End-->

			<!--Courses One Start-->
			<section class="courses-one" style="padding-top:120px" id="cursos">
					<div class="container">
							<div class="section-title text-center">
									<h2 class="section-title__title">Nuestros Cursos</h2>
							</div>
							<!-- courses-container -->
							<div class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}" style="justify-content: center;align-items: center;">
								<!-- Course Box -->
								<div class="product-wrap product-wrap-details m-1 course-container"
									*ngFor="let course of courses; let i = index;"
									[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
									<div fxLayout="column" fxFlex="100">
										<div 
											fxFlex="100" 
											fxLayout="column" 
											fxLayoutAlign="center center" 
											class="courses-one__single wow fadeInLeft"
											[routerLink]="['/studyPlan',course.courseId]"
											>
											<div class="courses-one__single-img">
												<img [src]="course.imageUrl" alt="" />
												<div class="overlay-text" style="cursor: pointer;">
													<p [routerLink]="activo ? ['/dashboard/courses'] : ['/courses']">Ver detalles</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- end course box -->
							</div>
					</div>
			</section>
			<!--Courses One End-->

			<!--Testimonials One Start-->
			<section *ngIf="testimonials.length > 0" class="testimonials-one clearfix" style="height: 300px;">
				<div class="auto-container">
						<div class="section-title text-center">
								<!--<span class="section-title__tagline">Testimonios</span>-->
								<h2 class="section-title__title">Reseñas</h2>
						</div>
						<div class="row">
								<div class="col-xl-12">
										<div class="testimonials-one__wrapper">
												<div class="testimonials-one__pattern"><img
																src="assets/new/images/pattern/testimonials-one-left-pattern.png" alt="" /></div>
												<div class="shape1"><img src="assets/new/images/shapes/thm-shape3.png" alt="" /></div>
										</div>
								</div>
						</div>
				</div>
			</section>
			<div *ngIf="testimonials.length > 0" class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}" style="justify-content: center;align-items: center;transform: translate(0px,-15vh);">
				<!-- Course Box -->
				<div style="margin-bottom: 2vh;"
							*ngFor="let t of testimonials | paginate: { id:'pagination',itemsPerPage: itemsPerPageP1, currentPage: currentPageP1, totalItems: rowsNumberP1 }; let i = index;"
							[@animate]="{value:'*',params:{delay: (i*300)+'ms', y:'50px'}}">
							<div fxLayout="column" fxFlex="100" class="testimonial-container">
								<div 
									fxFlex="100" 
									fxLayout="column" 
									fxLayoutAlign="center start" class="testimonial-content">
									<h4 class="testimonials-one__single-title">{{t?.title}}</h4>
									<!--<div class="meet-teachers-one__single-img_2">
										<img src="{{t?.imageUrl}}" alt=""/>
									</div>-->
									<p class="testimonials-one__single-text">{{t?.testimonial}}</p>
									<div class="testimonials-one__single-client-info">
											<div class="testimonials-one__single-client-info-text">
													<h5>{{t?.author}}</h5>
													<p>{{t?.ocupation}}</p>
											</div>
									</div>
								</div>
							</div>
							
				</div>
			</div>
			<div *ngIf="testimonials.length > 0" class="text-center" style="transform: translate(0px,-15vh)">
				<pagination-controls id="pagination" (pageChange)="changePagePaginator1($event)" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
			</div>
			<!--Testimonials One End-->

			<!--Start Registration Two-->
			<section class="registration-two">
					<div class="container">
							<div class="row">
									<div class="col-xl-12">
											<div class="registration-two__wrapper">
													<div class="shape2 wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms"><img
																	src="assets/new/images/shapes/thm-shape3.png" alt="" /></div>
													<div class="registration-two__left">
															<h2 class="registration-two__left-text" style="text-transform: none;">Estás a un curso de hacer crecer tu empresa como una bola de nieve</h2>
													</div>
													<div class="registration-two__right">
															<div class="registration-two__right-btn">
																	<a href="https://snowballcommunity.com" target="_blank" class="thm-btn">Registrate</a>
															</div>
													</div>
											</div>
									</div>

							</div>
					</div>
			</section>
			<!--End Registration Two-->    
									

			<!--Start Footer One
			<footer *ngIf="!activo" class="footer-one">
					<div class="footer-one__bg" style="background-image: url(assets/new/images/backgrounds/footer-v1-bg.jpg);">
					</div>
					<div class="footer-one__top">
							<div class="container">
									<div class="row">
											<div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
													<div class="footer-widget__column footer-widget__about">
															<div class="footer-widget__about-logo">
																	<a href="index.html"><img src="assets/new/images/resources/footer-logo.png" alt=""></a>
															</div>
													</div>
											</div>
											<div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
													<div class="footer-widget__column footer-widget__courses">
															<h3 class="footer-widget__title">Enlaces útiles</h3>
															<ul class="footer-widget__courses-list list-unstyled">
																	<li><a href="#">Fundación E</a></li>
																	<li><a href="#">4x4 Emprendedores</a></li>

															</ul>
													</div>
											</div>
											<div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
													<div class="footer-widget__column footer-widget__links">
															<h3 class="footer-widget__title">Legales</h3>
															<ul class="footer-widget__links-list list-unstyled">
																<li><a (click)="(isLogged)?openDialogPrivacy():services.goTo('/privacy')">Aviso de privacidad</a></li>
                          			<li><a (click)="(isLogged)?openDialogPrivacy():services.goTo('/privacy')">Términos y condiciones</a></li>
															</ul>
													</div>
											</div>
											<div class="col-xl-3 col-lg-6 col-md-6 col-12 wow animated fadeInUp" data-wow-delay="0.9s">
													<div class="footer-widget__column footer-widget__social-links">
															<ul class="footer-widget__social-links-list list-unstyled clearfix">
																	<li><a href="https://www.facebook.com/4x4Emprendedores/" target="_blank"><i class="fab fa-facebook"></i></a></li>
																	<li><a href="https://www.instagram.com/4x4emprendedores/" target="_blank"><i class="fab fa-instagram"></i></a></li>
																	<li><a href="https://www.youtube.com/channel/UCK4nuGlFDZhywzxZdWfNoOw" target="_blank"><i class="fab fa-youtube"></i></a></li>
																	<li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
															</ul>
													</div>
											</div>
									</div>
							</div>
					</div>
					<div class="footer-one__bottom">
							<div class="container">
									<div class="row">
											<div class="col-xl-12">
													<div class="footer-one__bottom-inner">
															<div class="footer-one__bottom-text text-center">
																<p class="text-center">
																	&copy; Todos los derechos reservados 2023 - 2033.
																	<br/>Ninguna parte de este material puede ser reproducido, copiado, distribuido o replicado sin el permiso por escrito de Fundación Educación para Emprendedores AC.
																	<br/>My 4x4 Academy es una empresa de Q22 Global SA de CV
																</p>
															</div>
													</div>
											</div>
									</div>
							</div>
					</div>
			</footer>
			End Footer One-->


	</div><!-- /.page-wrapper -->



	<div class="mobile-nav__wrapper">
			<div class="mobile-nav__overlay mobile-nav__toggler"></div>
			<!-- /.mobile-nav__overlay -->
			<div class="mobile-nav__content">
					<span class="mobile-nav__close mobile-nav__toggler"><i class="fa fa-times"></i></span>

					<div class="logo-box">
							<a href="index.html" aria-label="logo image"><img src="assets/new/images/resources/mobilemenu_logo.png"
											width="155" alt="" /></a>
					</div>
					<!-- /.logo-box -->
					<div class="mobile-nav__container"></div>
					<!-- /.mobile-nav__container -->

					<ul class="mobile-nav__contact list-unstyled">
							<li>
									<i class="icon-phone-call"></i>
									<a href="mailto:needhelp@packageName__.com">platicame@my4x4academy.com</a>
							</li>
							<li>
									<i class="icon-letter"></i>
									<a href="tel:666-888-0000">666 888 0000</a>
							</li>
					</ul><!-- /.mobile-nav__contact -->
					<div class="mobile-nav__top">
							<div class="mobile-nav__social">
									<li><a href="https://www.facebook.com/4x4Emprendedores/" target="_blank"><i class="fab fa-facebook"></i></a></li>
									<li><a href="https://www.instagram.com/4x4emprendedores/" target="_blank"><i class="fab fa-instagram"></i></a></li>
									<li><a href="https://www.youtube.com/channel/UCK4nuGlFDZhywzxZdWfNoOw" target="_blank"><i class="fab fa-youtube"></i></a></li>
									<li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
							</div><!-- /.mobile-nav__social -->
					</div><!-- /.mobile-nav__top -->
			</div>
			<!-- /.mobile-nav__content -->
	</div>
	<!-- /.mobile-nav__wrapper -->


	<a (click)="gotoTop()" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
</div>