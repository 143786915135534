<app-header-top-sessions></app-header-top-sessions>
<!---->
<div 
  class="container-auth"
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  >
  <div 
    fxFlex="100"
    fxLayout="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    >
    <div 
      class="session-form-hold min-h-60vh" [@animate]="{value:'*',params:{delay: '200ms', x:'250px'}}"
      fxFlex="100"
      fxLayoutAlign="center center"
      fxLayout="column"
      fxLayoutGap="1%"
      >
        <div fxFlex="60" fxLayoutAlign="center center">
          <img src="assets/images/logo.png">
        </div>
        <div fxFlex="40" fxLayoutAlign="center center">
          <h1>Validando información</h1>
        </div>
    </div>
  </div>
  <div fxFlex="100" fxFlexFill>
    <app-footer></app-footer>  
  </div>
</div>