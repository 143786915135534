import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { Location, DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Profile } from '../../users/profile';
import { RoleService } from 'app/views/services/role.service';
import { API } from 'app/views/shared/api';
import { LayoutService } from 'app/shared/services/layout.service';

@Component({
  selector: 'app-study-plan',
  templateUrl: './study-plan.component.html',
  styleUrls: ['./study-plan.component.css']
})
export class StudyPlanComponent implements OnInit,AfterViewInit {
  
  public course:any;
  public modules:any;
  public modifiedAt:string;
  userInfo:any;
  public isLogged:boolean = false;
  panelOpenState = false;
  layoutConf:any = {};
  expanded:boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public service: AdminService,
    private router: Router,
    private loader: AppLoaderService,
    private location:Location,
    public datepipe: DatePipe,
    public profile: Profile,
    public role: RoleService,
    private layout: LayoutService,
  ) { 
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.isLogged = (userInfo != null && userInfo != undefined);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const courseId = Number(params.get('id'));
      if (this.isLogged) {
        
        if (courseId) {
          this.getUserInformation();
          this.getCourse(courseId);
        } else {
          this.service.openDialog('No fue posible obtener la información del curso.');
          this.location.back();
        }

      } else {
        if (courseId) {
          //this.getUserInformation();
          this.getCourse(courseId);
        } else {
          this.service.openDialog('No fue posible obtener la información del curso.');
          this.location.back();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.layoutConf = this.layout.layoutConf;
    if (!this.layoutConf.hasOwnProperty('isMobile')) {
      this.layoutConf.isMobile = window.innerWidth <= 600 ? true : false;
    }
    if (this.layoutConf.isMobile) this.expanded = true;
    console.log(this.expanded,this.layoutConf.isMobile);
  }

  getCourse(courseId:number){
    this.loader.open('Cargando información');
    const loader = this.loader.getDialog();
    this.service.getCourse(courseId).subscribe(
      (data) => {
        loader.close();
        if (data.status == 200){
          let content = data.body.content;
          this.course = content.course;
          this.course.imageUrl = (this.course.imageUrl && this.course.imageUrl != '')?API.URL_BASE_STORAGE+this.course.imageUrl:'';
          this.modifiedAt = this.datepipe.transform(this.course?.modifiedAt, 'dd/MMM/yyyy','UTC-6','ES-MX');
          this.modules = content.modules;
          if (this.modules){
            this.modules.forEach(
              (module) => {
                if (module.topics) {
                  //module.topics = this.sortById(module.topics);
                  module.topics = this.service.sortByOrder(module.topics);
                }
                  
              }
            )
          }
        } else {
          this.service.openDialog('No fue posible obtener la información del curso.');
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  buyCourse(){
    this.router.navigate(['/dashboard/course/buy/',this.course.id]);
  }

  userHasLevelrequired(levelRequiredId:number):boolean{
    if(!this.isLogged) return false;
    const user:any = this.service.getUserInfo();
    return (user.userLevelId >= levelRequiredId)?true:false;
  }

  async getUserInformation(){
    this.userInfo = await this.service.getUserInfo();
  }

}
